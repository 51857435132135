import { Injectable, Injector, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformServer } from "@angular/common";
import { REQUEST } from "../../express.tokens";
import * as global from '../globals';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  public config: any = {
    appBaseHref: "/",
    country: "",
    currency: ""
  };
  private router: Router;

  private metaTagService: Meta;

  constructor(
    private _injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object,



  ) {


    if (isPlatformServer(this.platformId)) {
      // -> server rendered
      const req = this._injector.get(REQUEST);
      let parseURL = req.originalUrl.split("/");

      this.setInitialData(parseURL[1], "");
      // //
    } else {
      let parseURL = location.href.split("/");


      let env = window['environmentType'];
      let defaultLang = window['defaultLang'];
      let oemIndex = parseURL.indexOf(env);
      let langIndex = parseURL.indexOf(defaultLang)
      //
      if (window['countrySlugRequired']) {

        if (oemIndex < 0) {
          parseURL.splice(3, 0, env, defaultLang)

        }
        // ////
        this.setInitialData(parseURL[3], parseURL[4]);
      } else {
        if (oemIndex == 3 && parseURL[4] != window['defaultLang']) {
          //   //
          this.setInitialData(parseURL[3], parseURL[4]);
        } else {

          if (oemIndex == 3 && parseURL[4] == window['defaultLang']) {
            let currentUrl = window.location.href
            let newUrl = currentUrl.replace(env + '/' + defaultLang + '/', '')
            if (parseURL[5] && parseURL[5] != '') {
              window.location.href = newUrl;
            } else {
              setTimeout(() => {
                this.router = _injector.get(Router)
                this.router.navigate(['/'])
                  .then(() => {

                    window.location.reload();
                    ////

                  });
              })
            }





            return;
          }


          this.setInitialData(parseURL[1], "");
          // //
        }

      }



    }

  }

  setInitialData(url, lang) {

    //

    if (lang == 'en'
      || lang == 'zh-hk'
      || lang == 'de'
      || lang == 'zh-cn'
      || lang == 'zh-tw'
      || lang == 'nl'
      || lang == 'fr'
      || lang == 'it'
      || lang == 'sv'
      || lang == 'dk'
      || lang == 'es'



    ) {
      lang = lang;
    } else {
      lang = environment[window['environmentType']].defaultLang.toLowerCase();
    }
    var env = "";

    if (url == "") {
      env = window['environmentType'];
      if (window['countrySlugRequired']) {
        this.config.appBaseHref = "/" + env + "/" + lang;
      } else {
        //
        if (lang != window['defaultLang']) {

          this.config.appBaseHref = "/" + env + "/" + lang;
          global.setValue(env, lang, this.config.appBaseHref);
        } else {
          this.config.appBaseHref = location.origin;
          global.setValue(env, lang, '');
        }
      }

    }
    else if (url == 'austria' || url == "at" || url == "rpi" || url == "devat" ||
      url == "stageat" ||
      url == "stagefk" ||
      url == "stagefkat" ||
      url == "stagempbat" ||
      url == "stagempbde" ||
      url == "stagempbfr" ||
      url == "stagempbit" ||
      url == "stagempbch" ||
      url == "stagempbnl" ||
      url == "stagempbbe" ||
      url == "stagempbse" ||
      url == "stagempbdk" ||
      url == "stagempbes" ||
      url == "stagempbie" ||
      url == "stagempbuk" ||
      url == "masterftk" ||
      url == "masterftkat" ||
      url == "mastermpbat" ||
      url == "mastermpbde" ||
      url == "mastermpbfr" ||
      url == "mastermpbit" ||
      url == "mastermpbch" ||
      url == "mastermpbnl" ||
      url == "mastermpbbe" ||
      url == "mastermpbse" ||
      url == "mastermpbdk" ||
      url == "mastermpbes" ||
      url == "mastermpbie" ||
      url == "mastermpbuk" ||
      url == "fde" ||
      url == "fat" ||
      url == "mat" ||
      url == "mde" ||
      url == "mfr" ||
      url == "mit" ||
      url == "mch" ||
      url == "mnl" ||
      url == "mbe" ||
      url == "mse" ||
      url == "mdk" ||
      url == "mes" ||
      url == "mie" ||
      url == "muk"

    ) {
      env = url;
      ////
      this.config.appBaseHref = "/" + env + "/" + lang;
      global.setValue(env, lang, this.config.appBaseHref);
    }
    // //

  }

  set(k: string, v: any): void {
    this.config[k] = v;
  }

  get(k: string): any {

    return k ? this.config[k] : this.config;
  }
}
