import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import * as global from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { DesignSelectorComponent } from './design-selector/design-selector.component';
import { de_AT } from "./routes/de-AT";
import { en_US } from "./routes/en-US";
import { FAT_DE } from "./routes/FAT-DE";
import { FDE_DE } from "./routes/FDE-DE";
import { MAT_DE } from "./routes/MAT-DE";
import { MBE_FR } from "./routes/MBE-FR";
import { MBE_NL } from "./routes/MBE-NL";
import { MCH_FR } from "./routes/MCH-FR";
import { MCH_GSW } from "./routes/MCH-GSW";
import { MDE_DE } from "./routes/MDE-DE";
import { MDK_DA } from "./routes/MDK-DA";
import { MES_ES } from "./routes/MES-ES";
import { MFR_FR } from "./routes/MFR-FR";
import { MIE_EN } from "./routes/MIE-EN";
import { MIT_IT } from "./routes/MIT-IT";
import { MNL_NL } from "./routes/MNL-NL";
import { MSE_SV } from "./routes/MSE-SV";

import { MUK_EN } from "./routes/MUK-EN";

const routes: Routes = [
  { path: 'design-selector/:productCode/:groupCode', component: DesignSelectorComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  languageWiseRoutes = {
    'de-AT': de_AT,
    'en-US': en_US,
    'FAT-DE': FAT_DE,
    'FDE-DE': FDE_DE,
    
    'MAT-DE': MAT_DE,
    'MBE-FR': MBE_FR,
    'MBE-NL': MBE_NL,
    'MCH-FR': MCH_FR,
    'MCH-GSW': MCH_GSW,
    'MDE-DE': MDE_DE,
    'MDK-DA': MDK_DA,
    'MES-ES': MES_ES,
    'MFR-FR': MFR_FR,
    'MIE-EN': MIE_EN,
    'MIT-IT': MIT_IT,
    'MNL-NL': MNL_NL,
    'MSE-SV': MSE_SV,
   
    'MUK-EN': MUK_EN,

  }

  constructor(private router: Router) {


      let currentWebsiteLanguage = window.location.href.split('/')[4];
      if(currentWebsiteLanguage==undefined){
        currentWebsiteLanguage=window['defaultLang'].toUpperCase()
      }

      let contentFulLocales = environment[global.env].contentFullLocaleCode
      let routeKeyToLoad, routeFileToLoad;

      if (window['countrySlugRequired']) {
        routeKeyToLoad = currentWebsiteLanguage.toUpperCase();

      } else {
        if (contentFulLocales[currentWebsiteLanguage.toUpperCase()]) {
          routeKeyToLoad = currentWebsiteLanguage.toUpperCase()
        } else {
          routeKeyToLoad = window['defaultLang'].toUpperCase();
        }
      }
      routeFileToLoad = this.languageWiseRoutes[contentFulLocales[routeKeyToLoad]];

  //
      this.prepareRoutes(routeFileToLoad);


  }

  prepareRoutes(routesJson: any) {

    let routesArr = <Routes>[];
    routesArr = routesJson;
    routesArr.forEach(route => {
      routes.push(route);

    });
    routes.push(
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),

      },
      // {
      //   path: '',
      //   pathMatch: 'full',
      //   loadChildren: () => import('./fsc-module/fsc-module.module').then(m => m.FscModule),

      // },
      { path: '**', pathMatch: 'full', redirectTo: '404' },
      {
        path: '404', loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),

      }
    )
    this.router.resetConfig(routes);

  }

}
