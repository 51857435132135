import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../services/data.service';
import { GlobalService } from '../services/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as global from 'src/app/globals';
import { Router } from '@angular/router';
@Component({
    selector: 'app-sessionlogout',
    templateUrl: './sessionlogout.component.html',
    styleUrls: ['./sessionlogout.component.scss'],
    standalone: false
})
export class SessionlogoutComponent implements OnInit {

    type: any;
    timeOut: any = false;
    deleteInfo = { dontShowAgain: false };
    userPass: any;
    errMsg: any;
    email: any;
    passwordShow = false;
    showIcon = false;

    constructor(public activeModal: NgbActiveModal, private globalService: GlobalService, private dataService: DataService,private modalService: NgbModal,private router: Router) { }

    ngOnInit() {
        this.globalService.getSessionEnd().subscribe((data) => { this.timeOut = data
        
         });
    }
    cancel() {
        var info = { type: "SessionCancel" }
        this.globalService.setDeleteConfirmData(info);
        this.activeModal.close('Modal Closed');
        this.modalService.dismissAll();

        this.globalService.setSessionWindow();
    }


    submit() {

        setTimeout(() => {
            if( document.getElementById('passInput') ){
                document.getElementById('passInput').focus()}
          }, 100)
      
        var info = { type: 'SessionOut' }
        let userInfo = null;
        this.globalService.setUserData(userInfo);
        this.globalService.setCartLengthData(0);
        this.globalService.setDeleteConfirmData(info);
        this.globalService.setSessionWindow()
        this.activeModal.close('Modal Closed');
        this.modalService.dismissAll();
        localStorage.removeItem('address');

        localStorage.setItem('reload', 'false');
        localStorage.removeItem('reload');
        this.router.navigate(['/']).then(() => {
          location.reload();


        });
    }

    openPassWin() {
        // if(localStorage.getItem('userInfo')){
        //     this.activeModal.close('Modal Closed');
        // }
        // else{
            this.type = 'enterPass';
        // }
    }

    submitPass() {
        this.errMsg = '';
        var uEmail;
        if(localStorage.getItem('sessionData') != undefined && localStorage.getItem('sessionData') != null && localStorage.getItem('sessionData') != ''){
            uEmail = JSON.parse(localStorage.getItem('sessionData')).Email;
        }

        // if(JSON.parse(localStorage.getItem('userInfo')) != null){
        //     uEmail = JSON.parse(localStorage.getItem('userInfo')).Email;
        // }
        // else{
        //     uEmail =this.email;
        // }

        this.dataService.login(uEmail, this.userPass).subscribe((res: Response) => {
            
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                localStorage.removeItem('sessionData');
                localStorage.removeItem('session-terminated');
                localStorage.setItem('auth', resJSON.auth);
                var info = { type: "SessionCancel" };
                // let userInfo = {auth: resJSON.userAuth, langCode : global.lang, userType: resJSON.userType,
                //     FirstName: '', LastName: '', Newsletter: 0, SSO_ID: resJSON.sessionId,  userId:resJSON.userNumber
                // };
                var userData = resJSON;
                let userInfo  = {
                    FirstName: userData.data.FirstName, LastName: userData.data.LastName, Email: userData.data.Email, Username: userData.data.Username, isDesigner: userData.data.isDesigner, status: userData.status.code, auth: userData.auth,
                    userType: userData.data.user_type, userLoginKey: userData.data.userLoginKey, Newsletter: userData.data.Newsletter, langCode: userData.data.langCode, SSO_ID: resJSON.sessionId, userId: userData.data.userNumber,
                    UUID: userData.data.universalID
                  };
                  localStorage.setItem('reload','false');
                  this.globalService.setUserData(userInfo);
                  localStorage.setItem('userInfo', JSON.stringify(userInfo));
                  this.globalService.setSessionWindow();
                  this.globalService.startWatchingUserDuration()


                

                this.globalService.setDeleteConfirmData(info);
               


                this.activeModal.close('Modal Closed');
            }
            else {
                this.errMsg = resJSON.error.detail;
            }
        });
    }
    passFormat(val) {
        if (val == 1) {
          this.showIcon = true;
        }
    }
    passwordToggle() {
        this.passwordShow = !this.passwordShow;
    }
}
