import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as global from './globals';
import { environment } from 'src/environments/environment';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
    // contentHeader = new Headers({"Content-Type": "application/json","Access-Control-Allow-Origin":"*"});

    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any>{
        let version = Math.floor(Math.random() * (9999 - 100 + 0)) + 100;
        let httpHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'Access-Control-Allow-Origin':'*'
          });
        var apiAddress : string = environment[global.env].RESOURCE_URL_AMAZON+environment[global.env].RESOURCE_CONTAINER+environment[global.env].oemCode + '/keywords-'+global.lang+'.json?'+version;
        let options = {
            headers: httpHeaders
         };
        return Observable.create(observer => {
          this.http.get(apiAddress, options).subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();
                },
            error => {
                //  failed to retrieve from api, switch to local
                // this.http.get("/assets/i18n/de.json").subscribe((res: Response) => {
                //     observer.next(res.json());
                //     observer.complete();
                // })
            }
            );
        });
    }
}
