import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Output, Input, ViewContainerRef, NgZone } from '@angular/core';
import { DataService } from '../../services/data.service';
import { environment } from 'src/environments/environment';
import * as global from '../../globals';
import { GlobalService } from '../../services/global.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { LoginComponent } from '../../user/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotpasswordComponent } from '../../user/forgotpassword/forgotpassword.component';
import { RegisterComponent } from '../../user/register/register.component';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionlogoutComponent } from '../../sessionlogout/sessionlogout.component';
import { ContentfulService } from 'src/app/services/contentful.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ExternalService } from '../../services/external.service';
import { LoadDynamicComponentService } from 'src/app/services/loaddynamiccomponent.service';
import { takeUntil } from 'rxjs/operators';
declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-EL-Header-2',
    templateUrl: './EL-Header-2.component.html',
    styleUrls: ['./EL-Header-2.component.scss'],
    host: { '[id]': 'componentId' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ElHeaderV2Component implements OnInit {
  // @Output() public data: any;

  @Input()
  data: any;
  @Input()
  headerData: any;


  //data: any;
  componentName: any;
  @ViewChild('headerDiv') private headerDiv: ElementRef;
  @ViewChild('navbar') toggleNavBar: ElementRef;
  @ViewChild('headerWidget', { read: ViewContainerRef, static: true }) headerWidget: ViewContainerRef;
  categoryData;
  isMobileDevice;
  languageData: any;
  cartUrl: any;
  myphotosUrl: any;
  userInfo: any;
  arrCartLength: any;
  isUserTypeGuest: Boolean = false;
  userName: any;
  dicUrls: any;
  orderUrl: any;
  myprojectUrl: any;
  arrLanguage: any = [];
  languageLabel: any;
  logoImg: any;
  barOffset = 0;
  oemsDetail: any;
  profileIcon: any;
  ProfileLoggedInIcon: any;
  shoppingBagIcon: any;
  globeIcon: any;
  registerIcon: any;
  loginIcon: any;
  editProjectNameIcon: any;
  logoutIcon: any;
  myAccountUrl: any;
  mysharedProjectUrl: any;
  navbarTogglerOpen: any;
  navbarTogglerClick: any;
  deleteConfirmSubscription: Subscription;
  sessionLogoutModalSubscription: Subscription;
  loginModalSubscription: Subscription;
  languageDataSubscription: Subscription;
  forgotpasswordModalSubscription: Subscription;
  registerModalSubscription: Subscription;
  lastActivityAt: any;
  currentDateTime: any;
  headerLogo: any;
  headerProfile: any;
  cartBag: any;
  myPhotosMenu: any;
  langIcon: any;
  userLogin: any;
  userRegister: any;
  shoppingBag: any;
  editProjectIcon: any;
  userProfileIcon: any;
  checkmarkCross: any;
  newsletterIcon: any;
  hamburgerMenu: any;
  Userlogout: any;
  activeButton: any;
  TranslatedSysId: any;
  loginMenuIcon: any;
  photosMenuIcon: any;
  updateAvailable: boolean;
  showHeadersection: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  imageIconPath: string;
  badgeChristmas: string;
  christmasUrl: any;
  showBadge: boolean;

  constructor(private zone: NgZone, private translate: TranslateService, private dataService: DataService, private router: Router, private globalService: GlobalService, private modalService: NgbModal, private renderer: Renderer2, private contentfulservice: ContentfulService,

    private idle: Idle, private keepalive: Keepalive, private readonly svc: ExternalService, private loaddynamicComponentService: LoadDynamicComponentService, private cdr: ChangeDetectorRef) {

    this.imageIconPath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + environment[global.env].oemCode + "/icons/";
    //  this.headerLogo =this.headerData.fields.logo.fields.url//environment[global.env].RESOURCE_URL_AMAZON + 'logos/' + environment[global.env].oemCode + '/fs_logo.svg';
    this.headerProfile = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/profile-32.svg';
    this.cartBag = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/bag-32.svg';
    this.myPhotosMenu = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/my-photos-24.svg';
    this.langIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/globe-32.svg';
    this.userLogin = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/web-assets/login.svg';
    this.userRegister = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/web-assets/register.svg';
    this.shoppingBag = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/shopping-cart-24-turq.svg';
    this.editProjectIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/edit-project-name-32-turq.svg';
    this.userProfileIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/shopping-cart-24-turq.svg';
    this.checkmarkCross = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/checkmark-cross-24-red.svg';
    this.newsletterIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/checkmark-check-24-turq.svg';
    this.Userlogout = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/web-assets/logout.svg';
    this.hamburgerMenu = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/hamburger-24.svg';
    this.loginMenuIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/profile-loggedin-32.svg';
    this.photosMenuIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/photo-24-turq.svg';
    this.badgeChristmas = environment[global.env].RESOURCE_URL_AMAZON + 'productbadge/' + environment[global.env].oemCode + '/badge-christmas.png';

    this.svc.lazyMomentQuill().subscribe(_ => {


      this.callSessionWatcher()
    });

    let iconsObj = this.globalService.getIconsObj();
    this.logoImg = iconsObj['LOGO_ICON'];
    this.profileIcon = iconsObj['PROFILE_ICON'];
    this.ProfileLoggedInIcon = iconsObj['PROFILE_LOGGEDIN_ICON'];
    this.shoppingBagIcon = iconsObj['SHOPPING_BAG_ICON'];
    this.globeIcon = iconsObj['GLOBE_ICON'];
    this.loginIcon = iconsObj['LOGIN_ICON'];
    this.registerIcon = iconsObj['REGISTER_ICON'];
    this.editProjectNameIcon = iconsObj['EDIT_PROJECTNAME_ICON'];
    this.logoutIcon = iconsObj['LOGOUT_ICON'];
    // this.getOemSettings();

    // this.renderer.listen('window', 'click', (e: Event) => {
    //   this.navbarTogglerClick = $(e.target);
    //   this.navbarTogglerOpen = $(".navbar-collapse").hasClass("show");
    //   if (this.navbarTogglerOpen === true && !this.navbarTogglerClick.hasClass("navbar-toggler")) {
    //     $(".navbar-toggler").click();
    //   }
    // });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setPrintsCount(this.categoryData);
      }
    });
    this.contentfulservice.$newsletterUpdate.subscribe(response => {
      if (response == '1') {
        this.userInfo.Newsletter = '1';
      }
      else if (response == '0') {
        this.userInfo.Newsletter = '0';
      }
    });

    this.contentfulservice.$getTranslatedSysId.subscribe(response => {
      if (response && response != '') {
        this.TranslatedSysId = response;
        // 


      }
    });
    this.contentfulservice.$emitCloseHeaderWidget.subscribe(response => {
      if (response && response != '') {
        this.headerWidget.clear();


      }
    });
    this.contentfulservice.$isUserLoggedIn.pipe(takeUntil(this.destroyed$)).subscribe(response => {
      if (response === true) {
        setTimeout(() => {
          this.cdr.detectChanges()
        }, 1000);

      }
    })
  }

  callSessionWatcher() {

    this.lastActivityAt = new Date(localStorage.getItem('ng2Idle.lastactivity.doneat'))//moment(localStorage.getItem('ng2Idle.lastactivity.at')).toDate();
    this.currentDateTime = moment();
    let differenceInSeconds = this.currentDateTime.diff(this.lastActivityAt, 'seconds')



    // USER'S SESSION DETECTION STARTS
    if (differenceInSeconds > environment[global.env].userSessionTimeOutDuration) {
      //If user reopens tab after TimeOut period
      const showPopup = false
      this.idle.clearInterrupts();
      this.idle.stop();
      //localStorage.removeItem('ng2Idle.lastactivity.doneat');
      this.globalService.resetTimer(showPopup);


    } else {
      const isUserIsPresent = ((localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '')) ? false : true;
      if (isUserIsPresent) {
        this.globalService.startWatchingUserDuration()
      }

    }

    //  const isUserIsPresent = ((localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '')) ? false : true;
    //           if(isUserIsPresent){
    //           this.globalService.startWatchingUserDuration()
    //           }
    // USER'S SESSION DETECTION ENDS


  }

  ngOnInit(): void {
    this.headerWidget.clear();
    // 
    //
    // 


    this.headerLogo = this.headerData.fields.logo.fields.file.url
    window.scroll(0, 0)
    this.globalService.cartLengthData.subscribe(data => {
      this.arrCartLength = data;
      this.cdr.detectChanges();
    });
    this.globalService.userDetail.subscribe((data) => {
      this.userInfo = data;
      if (this.userInfo) {
        this.isUserTypeGuest = this.userInfo.userType == "Guest" ? true : false;
        this.setUserName();
        this.cdr.detectChanges();
      }
    });
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.setUserName();
    this.arrLanguage = environment[global.env].arrLanguage;
    let lang = global.lang;
    lang = lang.toUpperCase();
    for (let i = 0; i < this.arrLanguage.length; i++) {
      if (this.arrLanguage[i].lang == lang) {
        this.languageLabel = this.arrLanguage[i].lang;
      }
    }
    this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
    this.myAccountUrl = this.dicUrls['ACCOUNT_PREVIEW'];
    this.mysharedProjectUrl = this.dicUrls['PROJECT_SHARE'];
    this.orderUrl = this.dicUrls['ORDERS_PREVIEW'];
    this.myprojectUrl = this.dicUrls['PROJECTS_PREVIEW'];
    this.cartUrl = this.dicUrls['CART_PREVIEW'];
    this.myphotosUrl = this.dicUrls['PHOTOS_PREVIEW'];
    this.christmasUrl = this.dicUrls['CHRISTMAS_PREVIEW'];

    this.loadHeaderMenu();
    this.registerModalSubscription = this.globalService.getRegisterObservable().subscribe(data => {
      if (data) {
        //  
        this.showSignUpModal(data);
      }
    });

    this.loginModalSubscription = this.globalService.getLoginObservable().subscribe(data => {
      if (data) {
        this.showLogin(data);
      }
    });

    this.forgotpasswordModalSubscription = this.globalService.getForgotPasswordObservable().subscribe(data => {
      if (data) {
        this.forgotPasswordModal(data);
      }
    });

    this.sessionLogoutModalSubscription = this.globalService.getSessionLogoutObservable().subscribe(data => {
      if (data) {
        let sessionpopup = document.getElementById('sessionpopup')
        if (sessionpopup == null) {
          this.sessionLogoutModal(data);
        }
      }
    });
    // this.headerDotMenu();
  }

  getOemSettings() {
    this.dataService.getOemSettings().subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      this.oemsDetail = resJSON.data;
    });
  }


  returnZero() {
    return 0
  }
  showSignUpModal(data) {
    if (this.globalService.isUploadingFile || (localStorage.getItem('isUploadingFile') != null && localStorage.getItem('isUploadingFile') == 'Yes')) {
      this.contentfulservice.openuploadIsInProgressmodalRef(true);
      //
      return false;
    }
    const modalRef = this.modalService.open(RegisterComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
    modalRef.componentInstance.productdata = data;
  }

  showLogin(data) {
    const modalRef = this.modalService.open(LoginComponent, { size: 'md', windowClass: 'loginModalClass', centered: true, backdrop: 'static', keyboard: false });
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
    modalRef.componentInstance.productdata = data;
  }

  forgotPasswordModal(data) {
    const modalRef = this.modalService.open(ForgotpasswordComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.forgotPasswordData = data
  }




  loadHeaderMenu() {

    this.dataService.loadHeaderMenu().pipe(takeUntil(this.destroyed$)).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      let data = resJSON;
      //  
      //  this.globalService.setHeaderData(data);
      for (var i = 0; i < resJSON.length; i++) {
        var categoryThumbURL;
        if (this.isMobileDevice) {
          categoryThumbURL = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].AMAZON_CATEGORY_FOLDER_PATH + "200/" + resJSON[i].thumb_image;
        }
        else {
          categoryThumbURL = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].AMAZON_CATEGORY_FOLDER_PATH + "300/" + resJSON[i].thumb_image;
        }

        resJSON[i].fullSrc = categoryThumbURL;
        resJSON[i].show = false;

        if (this.headerData && this.headerData.fields.dropdownColumns && this.headerData.fields.dropdownColumns != '') {
          // 
          //   this.headerData.fields.dropdownColumns.map(item=>{
          // //    
          //     if(item.fields){
          //       let indexOfCategory= this.headerData.fields.dropdownColumns.findIndex(innerItem=>innerItem.fields.categoryCode==resJSON[i].code)
          //       
          //     }

          //   })

          let indexOfCategory = this.headerData.fields.dropdownColumns.findIndex(innerItem => {
            return innerItem.fields && innerItem.fields.categoryCode && innerItem.fields.categoryCode === resJSON[i].code;
          });          //
          if (indexOfCategory > -1) {
            let categoryData = this.headerData.fields.dropdownColumns[indexOfCategory].fields
            let columns = [];
            let indexvalue = 0;
            for (var key in categoryData) {
              if (typeof (categoryData[key]) == 'string' && key != 'internalName' && key != 'categoryCode' && !categoryData[key].includes('#') && !key.includes('Link')) {

                columns.push({ 'key': key, 'title': categoryData[key], data: [] })
              }
              let colIndex = columns.findIndex(subItems => subItems.key == key);
              if (colIndex > -1) {
                if (categoryData[key + 'Link']) {
                  columns[colIndex]['link'] = categoryData[key + 'Link']
                } else {
                  columns[colIndex]['link'] = false;
                }

              }

            }

            // 

            for (var key in categoryData) {

              if (typeof (categoryData[key]) == 'object') {
                indexvalue++
                let columnIndex = columns.findIndex(subItems => subItems.key == 'col' + indexvalue + 'Title')
                if (columnIndex > -1) {

                  categoryData[key].map(itemcolumns => {
                    if (itemcolumns.fields && itemcolumns.fields.label && !itemcolumns.fields.label.includes('#'))
                      columns[columnIndex].data.push(itemcolumns.fields)
                  })
                  // columns[columnIndex].data = categoryData[key]
                }
              }

              // indexValue++

            }


            resJSON[i].dropDownItems = columns;
            //  
          }




        }

      }

      if (this.headerData.fields && this.headerData.fields.widget) {

        if (Array.isArray(this.headerData.fields.widget)) {
          this.headerData.fields.widget.map(item => {
            const templateVersion = item.fields && (item.fields.heroBannerDesignVersion || item.fields.txtBlockSelectDesignTemplate || item.fields.imageBoxCarouselDesignVersion || item.fields.bodyTxtSelectDesign || item.fields.testimonialBlockDesign || item.fields.templateCode)

            if (templateVersion) {
              this.loaddynamicComponentService.loadComponentInstance(templateVersion, item, this.headerWidget, false)

              document.body.className += ' headerWidget';
            }

          })
        } else {
          let item = this.headerData.fields.widget;

          const templateVersion = item.fields && (item.fields.heroBannerDesignVersion || item.fields.txtBlockSelectDesignTemplate || item.fields.imageBoxCarouselDesignVersion || item.fields.bodyTxtSelectDesign || item.fields.testimonialBlockDesign || item.fields.templateCode)

          if (templateVersion) {
            this.loaddynamicComponentService.loadComponentInstance(templateVersion, item, this.headerWidget, false)

            document.body.className += ' headerWidget';
          }

        }

        setTimeout(() => {
          this.showHeadersection = true;
          this.cdr.detectChanges()
        }, 20);


      }
      else {
        this.showHeadersection = true;
        this.cdr.detectChanges()
      }
      //// this.loaddynamicComponentService.loadComponentInstance(templateVersion, item, this.maincontentContainer, this.componentEntryId)


      let resSTR1 = JSON.stringify(data);
      let resJSON1 = JSON.parse(resSTR1);
      this.categoryData = resJSON1.filter(item => item.isMenu == 'Yes');
      // this.zone.run(() => {
      this.cdr.detectChanges();

      const urlPattern = /^[\w\/]+(\?[\w%&=]+)?$/;


      if (this.categoryData) {
        for (let i = 0; i < this.categoryData.length; i++) {
          if (this.categoryData[i].dropDownItems) {
            let item: any = this.categoryData[i].dropDownItems;
            if (item) {
              for (let j = 0; j < item.length; j++) {
                let dropDownitem = item[j];
                if (dropDownitem.data) {
                  for (let k = 0; k < dropDownitem.data.length; k++) {
                    let itemdata = dropDownitem.data[k];
                    let itemLink = dropDownitem.data[k].link;
                    let isValidUrl = urlPattern.test(itemLink);
                    if (isValidUrl) {
                      let parts = itemLink.split('?');
                      let beforeQuestionMark = itemLink.split('?')[0];
                      if (parts.length > 1) {
                        let queryParams = parts[1];
                        let queryParamsArray = queryParams.split('&');
                        let keyValuePairs = queryParamsArray.map(param => param.split('='));
                        let keys = keyValuePairs[0][0];
                        let values = keyValuePairs[0][1];
                        itemdata.queryVariable = keys;
                        itemdata.queryVariableValue = values;
                        itemdata.link = beforeQuestionMark;
                      }
                    }
                    else {
                      itemdata.queryVariable = '';
                      itemdata.queryVariableValue = '';
                    }
                  }
                }
              }
            }
          }
        }
      }


      // });
      // 
      this.setPrintsCount(resJSON1);
      this.globalService.setHeaderData(resJSON1);
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (this.userInfo) {
        this.isUserTypeGuest = this.userInfo.userType == "Guest" ? true : false;
        this.globalService.setUserData(this.userInfo);
        this.setUserName();
        this.loadCart(this.userInfo.auth);
        // this.isShowIcons = this.isUserTypeGuest == true ? true: false;
        // this.isShowIcons = false;
      }
      else {
        // this.isShowIcons = true;
      }
    }, (error) => {

    });

  }
  buildQueryParams(menuItem): any {
    const queryParams = {};
    if (menuItem.queryVariable !== '') {
      queryParams[menuItem.queryVariable] = menuItem.queryVariableValue;
      return queryParams;
    }

    return null;
  }
  setPrintsCount(headerData) {
    if (headerData == undefined) {
      return;
    }
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo == null || userInfo == undefined || userInfo == '') {
      let widgetStatus = localStorage.getItem('header-widget-closed');

      var myItem = localStorage.getItem('interacted');
      localStorage.clear();
      localStorage.setItem('interacted', myItem);


      localStorage.setItem('header-widget-closed', widgetStatus)

      localStorage.removeItem("arrCurrentPhotos")
    }
    var arrCurrentPhotos: any = localStorage.getItem("arrCurrentPhotos");
    if (arrCurrentPhotos != null) {
      arrCurrentPhotos = JSON.parse(localStorage.getItem("arrCurrentPhotos"));
      if (arrCurrentPhotos.length > 0) {
        for (var i = 0; i < headerData.length; i++) {
          if (headerData[i].preview == "PRINT_PREVIEW") {
            headerData[i].printCounts = arrCurrentPhotos.length;
            break;
          }
        }
      }
    }
    else {
      for (var i = 0; i < headerData.length; i++) {
        if (headerData[i].preview == "PRINT_PREVIEW") {
          headerData[i].printCounts = 0;
          break;
        }
      }
    }
  }
  goToLink(url) {
    this.router.navigate([url]);
  }

  gotoPrints($event) {
    $event.stopPropagation();

    var url;
    if (global.lang == 'de') {
      url = 'fotoabzuege/bestellen';
    }
    else {
      url = 'photo-prints/order';
    }
    this.router.navigate([url]);
  }

  loadCart(authID) {
    this.dataService.getCartCount(authID).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.data) {
        this.globalService.setCartLengthData(resJSON.data.items);
      }
    });
  }

  setUserName() {

    if (this.globalService.gettLanguageData() != undefined) {
      this.languageData = this.globalService.gettLanguageData();
      this.setName();
    }
    else {
      this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
        if (data) {
          this.languageData = data;
          this.setName();
        }
      });
    }
  }

  setName() {
    let badgeText = this.languageData.web.nav_christmas_txt
    if (badgeText) {
      if (badgeText.charAt(0) === '#') {
        this.showBadge = false
      } else {
        this.showBadge = true
      }
    } else {
      this.showBadge = false
    }
    if (this.userInfo == null || this.userInfo == '' || this.userInfo == undefined) {
      return;
    }
    if (this.userInfo.FirstName == '' || this.userInfo.LastName == '') {
      if (this.userInfo.Email != undefined && this.userInfo.Email != '') {
        this.userName = this.userInfo.Email;
      } else {
        this.userName = this.languageData.web.header_guest_user;
      }

    }
    else if (this.userInfo.FirstName == undefined || this.userInfo.LastName == undefined) {
      // this.userName = this.languageData.web.header_guest_user;
      if (this.userInfo.Email != undefined && this.userInfo.Email != '') {
        this.userName = this.userInfo.Email;
      } else {
        this.userName = this.languageData.web.header_guest_user;
      }
    }
    else {
      this.userName = this.userInfo.FirstName + " " + this.userInfo.LastName;
    }
  }

  logout() {

    if (this.globalService.isUploadingFile || (localStorage.getItem('isUploadingFile') != null && localStorage.getItem('isUploadingFile') == 'Yes')) {
      this.contentfulservice.openuploadIsInProgressmodalRef(true);
      //
      return false;
    } else {

      var myItem = localStorage.getItem('interacted');
      localStorage.clear();
      localStorage.setItem('interacted', myItem);


      this.idle.stop();
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.globalService.setUserData(this.userInfo);
      this.globalService.setCartLengthData(0);
      this.router.navigate(['/']);
      localStorage.setItem('reload', 'true');
    }

    // this.isShowIcons = true;

  }


  changeLanguage(langCode) {
    //
    if (langCode.toLowerCase() == global.lang) {
      return;
    }
    var info = environment[global.env].contentFullLocaleCode;
    const conentfulLangCode = info[langCode.toLocaleUpperCase()];
    // 
    if (this.TranslatedSysId && this.TranslatedSysId != '') {
      this.contentfulservice.getsingleContent(this.TranslatedSysId, conentfulLangCode).then(data => {

        if (data && data.fields && data.fields['url']) {

          const tranlatedUrl = data.fields['url'];
          const currentUrl = this.router.url;
          const currentUrlQueryParam = currentUrl.split('?');
          let url;
          // 
          if (currentUrl != '/') {
            if (window['countrySlugRequired']) {

              url = global.env + '/' + langCode.toLowerCase() + '/' + tranlatedUrl;
            } else {

              if (langCode.toLowerCase() != environment[global.env].defaultLang.toLowerCase()) {
                url = global.env + '/' + langCode.toLowerCase() + '/' + tranlatedUrl;
              } else {
                url = '/' + tranlatedUrl;
                //
              }

            }

          } else {
            if (window['countrySlugRequired']) {
              url = global.env + '/' + langCode.toLowerCase();
            } else {

              if (langCode.toLowerCase() != environment[global.env].defaultLang.toLowerCase()) {
                url = global.env + '/' + langCode.toLowerCase();
              } else {
                url = '';
              }

            }

          }

          if (currentUrlQueryParam && currentUrlQueryParam[1]) {
            url = url + '?' + currentUrlQueryParam[1];
          }
          window.location.href = url;
        }
      })


    } else {
      window.location.href = global.env + '/' + langCode.toLowerCase();;
    }



  }
  isValue: number = 0;

  goToPage(url, reload = false) {
    this.isValue = url;
    // this.activeButton = url;
    // $('.dropdown-menu-end').css({ 'display': 'none' });
    // if(JSON.parse(localStorage.getItem('userInfo')) == null){
    //     this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //     this.globalService.setUserData(this.userInfo);
    //     this.globalService.setCartLengthData(0);
    // }
    let currentRoute = this.router.url.split('/')[1].split('?')[0];
    this.router.navigate([url]).then(result => {
      if (reload && currentRoute != 'myphotos' && currentRoute != 'myphotos' && localStorage.getItem('userInfo') != null && localStorage.getItem('userInfo') != undefined) {
        //  window.location.reload()
      }
    });
  }
  out() {

  }
  over() {

    $('.dropdown-menu-end').removeAttr('style');

  }

  sessionLogoutModal(info) {
    if (info.type == 'SessionOut') {
      this.openSessionModal(info);

    } else if (info.type == 'nullOuth') {
      this.router.navigate(['/'])
        .then(() => {
          this.openSessionModal(info);
        });
    }
  }
  openSessionModal(info) {
    // if (!this.modalService.hasOpenModals()) {
    //   const modalRef = this.modalService.open(SessionlogoutComponent, { size: "sm", centered: true, backdrop: "static", keyboard: false });
    //   modalRef.componentInstance.type = info.type;
    //   modalRef.result.then(result => { }).catch(error => {
    //   });

    //   this.deleteConfirmSubscription = this.globalService.getDeleteConfirmObservable().subscribe(data => {
    //     if (data) {

    //       this.deleteConfirmSubscription.unsubscribe();
    //       if (data.type == "logout" || data.type == "SessionOut" || data.type == "nullOuth") {
    //         this.globalService.editorlogout();
    //       } else if (data.type == "SessionCancel") {
    //         this.globalService.sessionCancel();
    //       }
    //     }
    //   });

    // }

    const modalRef = this.modalService.open(SessionlogoutComponent, { size: "sm", centered: true, backdrop: "static", keyboard: false });
    modalRef.componentInstance.type = info.type;
    modalRef.result.then(result => { }).catch(error => {
    });

    this.deleteConfirmSubscription = this.globalService.getDeleteConfirmObservable().subscribe(data => {
      if (data) {

        this.deleteConfirmSubscription.unsubscribe();
        if (data.type == "logout" || data.type == "SessionOut" || data.type == "nullOuth") {
          this.globalService.editorlogout();
        } else if (data.type == "SessionCancel") {
          this.globalService.sessionCancel();
        }
      }
    });
  }





  subscribeNewsletter(userInfo, redirectTo) {
    let currentUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.dataService.newsletterSubscribe(userInfo.Email, 'subscribe').subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        this.userInfo['Newsletter'] = '1';
        currentUserInfo['Newsletter'] = '1';
        userInfo['Newsletter'] = '1';
        localStorage.setItem('userInfo', JSON.stringify(currentUserInfo));

        this.contentfulservice.emitNewsletterUpdate('1');
        this.contentfulservice.opensuccessModal('Newsletter', resJSON)

      }
      else {

      }
    });

  }

  @HostListener("window:keypress", ["$event"])
  @HostListener("window:mousemove", ["$event"])
  @HostListener("window:touchstart", ["$event"])
  private eventRouter(e) {
    //	this.globalService.resetTimer();
  }

  @HostListener('window:scroll', ['$event'])


  onWindowScroll() {
    let element = document.querySelector('#cd-header') as HTMLElement;
    let elementRouterHeight = document.querySelector('.router-height') as HTMLElement;
    // 
    // if (window.pageYOffset > element.clientHeight) {
    if (window.pageYOffset > 0) {
      if (element) {
        element.classList.add('navbar-fixed');
      }

      elementRouterHeight.classList.add('navbar-fixed');
    } else {
      if (element) {
        element.classList.remove('navbar-fixed');
      }

      elementRouterHeight.classList.remove('navbar-fixed');
    }
  }

  mobileHamburger() {
    $(".navbar").toggleClass("collpaseMenuOpen");
    $("body").toggleClass("toggleSidePanel")
  }

  closeSidePanel() {
    $(".navbar-collapse").removeClass("show");
    $(".navbar").removeClass("collpaseMenuOpen");
    $("body").removeClass("toggleSidePanel")
  }

  checkExpandCollapse(index) {
    setTimeout(() => {

      if (document.getElementById("menuItem" + index) && document.getElementById("menuItem" + index).classList.contains('show')) {
        this.categoryData.map((item, indexValue) => {
          if (indexValue == index) {
            document.getElementById("menuParent" + index).classList.add("menuParentExpanded")
          } else {
            document.getElementById("menuParent" + indexValue).classList.remove("menuParentExpanded")
          }
        })
      }
    }, 500);
  }


}
