<footer *ngIf="footerContent!=''">
    <div class="container">
        <div class="row" [ngClass]="{'newCol' : newArr.length<6}">
            <ng-container *ngFor="let item of footerContent;let i=index;">
                <div class="col-12 col-sm-6 col-md-4" *ngIf="item.title!='#'">
                    <div class="footer-title mb-2" *ngIf="!item.hideTitle">{{item.title}}</div>
    
                    <ng-container *ngIf="item.imagesArray && !item.hideTitle">
                        <a [attr.href] = "images.link ? images.link : null" class="footer-payment d-inline-block" *ngFor="let images of item.imagesArray">
                            <img src="{{images.icon.fields.file.url}}" title="{{images.icon.fields.title}}" alt="{{images.icon.fields.description}}" *ngIf="images.icon">
                        </a>
                    </ng-container>

                    <ng-container *ngFor="let text of item.textArray">
                        
                        <ng-container *ngIf="item.textArray && !item.hideTitle && text.link && text.link.changingThisBreaksApplicationSecurity && (text.link.changingThisBreaksApplicationSecurity.includes('https://') || text.link.changingThisBreaksApplicationSecurity.includes('javascript') )">
                          
                            <ng-container>
                                <a target="_blank" *ngIf="item.textArray && !item.hideTitle && text.link && text.link.changingThisBreaksApplicationSecurity && text.link.changingThisBreaksApplicationSecurity.includes('https://')" [href]="text.link.changingThisBreaksApplicationSecurity" class="footer-link 1">{{text.label}} </a>
                          
                            </ng-container>

                            <ng-container>
                                <a  *ngIf="item.textArray && !item.hideTitle && text.link && text.link.changingThisBreaksApplicationSecurity && text.link.changingThisBreaksApplicationSecurity.includes('javascript')" href="javascript:Didomi.preferences.show()" class="footer-link 1">{{text.label}} </a>
                          
                            </ng-container>


                        </ng-container>
                        
                        
                       
                        <a *ngIf="item.textArray && !item.hideTitle && text.link && text.link.changingThisBreaksApplicationSecurity  && !text.link.changingThisBreaksApplicationSecurity.includes('https://') && !text.link.changingThisBreaksApplicationSecurity.includes('javascript')" routerLink="/{{text.link.changingThisBreaksApplicationSecurity}}" class="footer-link 1">{{text.label}} </a>

                    </ng-container>
                    <ng-container *ngIf="item.hideTitle">
                        <div class="footer-title mb-2" *ngFor="let title of item.textArray;let isLast = last;">
                            <a [href]="title.link.changingThisBreaksApplicationSecurity" *ngIf="item.textArray && item.textArray!='' && title.link && title.link.changingThisBreaksApplicationSecurity && (title.link.changingThisBreaksApplicationSecurity.includes('https://') || title.link.changingThisBreaksApplicationSecurity.includes('javascript'))" >
                              {{title.label}}  <span *ngIf="!isLast">|</span> </a>

                              <a routerLink="/{{title.link.changingThisBreaksApplicationSecurity}}" *ngIf="item.textArray && item.textArray!='' && title.link && title.link.changingThisBreaksApplicationSecurity && !title.link.changingThisBreaksApplicationSecurity.includes('https://')  && !title.link.changingThisBreaksApplicationSecurity.includes('javascript')">
                                {{title.label}}  <span *ngIf="!isLast">|</span> </a>

                        </div>
                        <div class="footer-link 2" *ngIf="item.textArray && item.textArray!=''">
                            <div *ngFor="let item of item.linkArray">
                                {{item.label}} <br>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
            <ng-container *ngIf="newArr.length<6">
                <div class="col-12 col-sm-6 col-md-4">
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                </div>
            </ng-container>

        </div>
    </div>
</footer>