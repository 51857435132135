
<ng-container *ngIf="DesignGroup == 'FS' && headerVersion"  id="HEADER-SECTION">
    <app-fsc-header-v1 *ngIf="headerVersion=='1'" [headerData]="headerContentData"></app-fsc-header-v1>
</ng-container>

<ng-container *ngIf="DesignGroup == 'FTK' && headerVersion && !isOfflineApp"  id="HEADER-SECTION">
    <app-EL-Header-1 *ngIf="headerVersion=='1'" [headerData]="headerContentData"></app-EL-Header-1>
    <app-EL-Header-2 *ngIf="headerVersion=='2'" [headerData]="headerContentData"></app-EL-Header-2>
</ng-container>

<ng-container *ngIf="DesignGroup == 'MPB' && headerVersion && !isOfflineApp"  id="HEADER-SECTION">
    <app-mpb-headerv1 *ngIf="headerVersion=='1'" [headerData]="headerContentData"></app-mpb-headerv1>
    <app-mpb-headerv2 *ngIf="headerVersion=='2'" [headerData]="headerContentData"></app-mpb-headerv2>
    <!-- <app-EL-Header-2 *ngIf="headerVersion=='2'" [headerData]="headerContentData"></app-EL-Header-2> -->
</ng-container>



<div class="router-height" [ngClass]="{'desktopApp': isOfflineApp}">
    <router-outlet></router-outlet>
</div>




<div *ngIf="DesignGroup == 'FS' && footerVersion && !isOfflineApp">
    <app-fsc-footer-v1 *ngIf="footerVersion=='1'" [footerData]="footerContentData"></app-fsc-footer-v1>
</div>

<div *ngIf="DesignGroup == 'FTK' && footerVersion && !isOfflineApp">
    <app-EL-Footer-1 *ngIf="footerVersion=='1'" [footerData]="footerContentData"></app-EL-Footer-1>
</div>

<div *ngIf="DesignGroup == 'MPB' && footerVersion && !isOfflineApp">
    <app-EL-Footer-1 *ngIf="footerVersion=='1'" [footerData]="footerContentData"></app-EL-Footer-1>
</div>

<div id="window-bar" class="window-bar sticky-bar" [hidden]="!isUploadRunning">
    <img src="{{popupCrossCheckmark}}" alt="" title="" class="close-bar" (click)="closeUploadBar()">

    <div class="apptool_progressBar">
        <div class="circle_progress show-tooltip" id="roundCircle" data-value='80'>
            <span class="progress-left">
                <span class="progress-bar"></span>
            </span>
            <span class="progress-right">
                <span class="progress-bar"></span>
            </span>
            <div class="progress-value cursor-pointer w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                <div class="fw-bold">{{uploadProgressValue}}<sup class="small">%</sup>                
                </div>
            </div>
        </div>
        <small class="top-tooltip" [hidden]="!isUploadRunning">{{uploadingMsgTooltip}}</small>
    </div>

</div>

<!-- <div class="window-bar sticky-bar" [hidden]="!isUploadRunning">
    <div class="progress">
        <div #progressBar class="progress-bar" role="progressbar" style="width: 2%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{uploadingMsg}}</div>
    </div>
</div> -->

<div class="modal fade" *ngIf="isConfirmDisplayModal" id="confirmUploadedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p>Photos Uploaded </p>
            </div>
            <div class="modal-body">
                <p> Your product is ready ! Have a look </p>
            </div>
            <div class="modal-footer">

                <button class="btn btn-primary" type="button" data-bs-dismiss="modal" (click)="gotoNextPage()">ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="commonReUsableModalForOpenEditor" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header p-0">
                <!-- <p></p> -->
                <!-- <a class="closeModal" [attr.aria-label]="Close" data-bs-dismiss="modal">
          <img src="assets/images/close.png">
        </a> -->
            </div>
            <!-- <div class="modal-body">
        <p class="mb-3" *ngIf="pageType == 'inPage-Editor'">{{'web.inpage_select_upload_option_text' | translate}} </p>
        <p class="mb-3" *ngIf="pageType == 'book-Editor'">{{'web.bookeditor_select_upload_option_text' | translate}}
        </p>
        <div *ngIf="pageType == 'inPage-Editor'">
          <div class="icon p-2">
            <input type="file" multiple #fileInput accept=".jpg,.jpeg" style="display: none;" (change)="onUpload()" />
            <img (click)="uploadPhotos('local')" class="me-5" src="../assets/images/upload_device.png" alt="">
            <img (click)="uploadPhotos('album')" src="../assets/images/my_album_upload.png" alt="">
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="pageType == 'inPage-Editor'">
      </div>
      <div class="modal-footer" *ngIf="pageType == 'book-Editor'">
        <button class="btn btn-primary" type="button" data-bs-dismiss="modal" (click)="gotoBookEditor('quickBook')">Quick
          View</button>
        <button class="btn btn-primary" type="button" data-bs-dismiss="modal" (click)="gotoBookEditor('bookEditor')">Full
          View</button>
      </div> -->
        </div>
    </div>
</div>

<div id="pwaModal" class="modal modal-custom" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content p-3">
            <div class="modal-header p-0 bg-transparent border-0">
                <h3 class="mb-0">{{'web.open_pwa_popup_title' | translate}}</h3>
                <!-- <a class="close cursor-pointer" data-bs-dismiss="modal">
                    <img class="w-100" alt="" title="" src="https://cdn.sw.fit/FS-AT/icons/checkmark-cross-32.svg">
                </a> -->
            </div>
            <div class="modal-body px-0 py-3">
                <p class="text_type3 text-start">{{'web.myphotos_cancel_upload_description' | translate}}</p>
            </div>
            <div class="modal-footer align-items-start p-0 border-0 flex-nowrap">
                <button class="btn btn-md w-50 btn-light" data-bs-dismiss="modal">{{'web.myphotos_cancel_upload_no' | translate}}</button>

                <button class="btn btn-md w-50 btn-primary" data-bs-dismiss="modal">{{'web.myphotos_cancel_upload_yes' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div #smartbannerScriptDiv></div>


