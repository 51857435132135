import { OnInit, Component, ComponentFactoryResolver, ElementRef, Inject, Renderer2, ViewChild, ViewContainerRef, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentChecked, TemplateRef, Input, ViewEncapsulation, } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as global from 'src/app/globals';
import { GlobalService } from './services/global.service';
import { UploadService } from './services/upload.service';
import { DataService } from './services/data.service';
import { DOCUMENT } from '@angular/common';
import { ContentfulService } from 'src/app/services/contentful.service';
import { filter, map } from 'rxjs/operators';
import { UtilityService } from './services/utility.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PwaService } from './services/pwa.service';
import { SwUpdate } from '@angular/service-worker';
import { timer, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxSmartBannerService } from '@netcreaties/ngx-smart-banner';
import { Platform } from '@angular/cdk/platform';
import { SessionlogoutComponent } from './sessionlogout/sessionlogout.component';
import { QRCodeComponent } from 'angularx-qrcode';

declare var $: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    //changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AppComponent {
  ThemeCollections = {
    FS: {
      'primary-color': '#45CED8',
      'btn-primary-color': '#45CED8',
      'secondary-color': '#474747',
      'text-gray': '#4a4a4a',
      'light': '#F7F8FC',
      'btnDanger': '#A80029',
      'warning': '#FFB23C',
      'color-white': '#fff',
      'theme-color1': '#2CBAB5',
      'light-primary': '#85d7dc',
      'main-font': 'Montserrat',
      'font-url': '',
      'primary-lightColor': '#a1e8ed',

      // New custom-color start
      'custom-font' : 'nunitoSans',
      'custom-primary' : '#6F54F3',
      'custom-primary-rgb' : '111, 84, 243',
      'custom-black' : '#070707',
      'custom-black-rgb': '7, 7, 7',
      'custom-success' : '#16A34A',
      'custom-success-rgb' : '22, 163, 74',
      'custom-danger' : '#EF4444',
      'custom-danger-rgb' : '239, 68, 68',
      'custom-warning' : '#FFC74E',
      'custom-warning-rgb' : '255, 199, 78',
    },
    FTK: {
      'secondary-color': '#474747',
      'warning': '#FFB23C',
      'light': '#F7F8FC',
      'color-white': '#fff',
      'danger': '#D01c00',
      'btnDanger': '#D63517',
      'circleBg': '#669900',
      'light-primary': '#96d41b',
      'gradient-light': '#94b74c',

      // Font-family variable Start
      'banner-font': 'gumption',
      'footer-font': 'notosans',
      'main-font': 'notosans',
      'font-url': '',
      // Font-family variable End

      'primary-color': '#669900', //green
      'theme-color1': '#669900',  //green
      'primary-color2': '#474747', //grey
      'text-gray': '#474747',  //grey
      'spot-color': '#66990014',
      'shadow-color': 'rgba(102, 153, 0, 0.25)',
      'primary-lightColor': '#88c410'

    },
    MPB: {
      'primary-color': '#000', //black
      'theme-color1': '#a96600',  //orcher
      'primary-color2': '#a96600', //ocher
      'text-gray': '#000', //black
      'spot-color': '#A9660014',
      'shadow-color': 'rgba(169, 102, 0, 0.25)',

      // Font-family variable Start
      'banner-font': 'oswald',
      'footer-font': 'oswald',
      'main-font': 'Quicksand',
      'font-url': '',
      // Font-family variable End

      'secondary-color': '#1A202C',
      'light': '#F7F8FC',
      'btnDanger': '#B00000',
      'danger': '#A80029',
      'warning': '#FFB23C',
      'color-white': '#fff',
      'circleBg': '#000',
      'light-primary': '#a96600',
      'gradient-light': '#ffb84d',
      'primary-lightColor': '#525050'
    },
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showHeader: Boolean = true;
  showFooter: Boolean = false;
  title = '';
  //uploadCounterSubscription: Subscription;
  // uploadStatusSubscription: Subscription;
  uploadingFiles;
  total_files;
  isUploadRunning;
  uploadingMsg;
  isConfirmDisplayModal: Boolean = false;
  isEditor;
  editortwoComponentRef
  isDisplayConfirmModal;
  imageUploadCounter;
  arrAlbumImages = [];
  projectName;
  @ViewChild("progressBar") divProgressBar: ElementRef;

  @ViewChild("smartbannerScriptDiv") smartbannerScriptDiv: ElementRef;
  uploadInfo: any;
  seoDataSubscription: Subscription;
  showFooterOnScroll: boolean;
  updateAvailable: boolean;
  DesignGroup: any;

  // @ViewChild('openPwaModal') openPwaModalRef: TemplateRef<any>;s

  headerContentData: any;
  headerVersion: any;
  isMobileDevice: any;
  footerVersion: any;
  footerContentData: any;
  uploadProgressValue: any = 0;
  popupCrossCheckmark: any;
  uploadingMsgTooltip: any;
  scriptLoaded: boolean = false;
  noOfTimes: any = 0
  isOfflineApp: boolean = false;
  //errorArray:any=false;


  @HostListener('document:click', ['$event'])
  @HostListener("window:keypress", ["$event"])
  @HostListener("window:mousemove", ["$event"])
  //@HostListener('window:scroll', ['$event'])
  callScriptFunctions(e) {
    //
    localStorage.setItem('interacted', 'Yes')

    // 
    let currentUrl = window.location.href;
    let urlSegmentFromEnd = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
    let fromApp = (urlSegmentFromEnd == 'app') ? true : false;
    if (!this.scriptLoaded && !fromApp && this.isOfflineApp == false) {
      this.pwaservice.setInitialScripts();


      if (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || currentUrl.includes("myphotos") || currentUrl.includes("myphotos")) {
        const scriptList = document.querySelectorAll("script[type='text/javascript']")

        //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
        const convertedNodeList = Array.from(scriptList)
        const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")

        if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
          scriptToRemove.parentNode.removeChild(scriptToRemove)
        }
      } else {
        // 
        this.pwaservice.manageTrustBadge()
      }

      this.scriptLoaded = true;
    }
    //
  }
  @HostListener('window:scroll', ['$event'])
  callScriptFunctionsOnscroll(e) {

    var isAndroidMobile = navigator.userAgent.match(/Android/i)///iPhone|iPad|iPod/i.test(navigator.userAgent);
    var isIosMobile = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i));
    // 
    if (isAndroidMobile || isIosMobile) {
      let currentUrl = window.location.href;
      let urlSegmentFromEnd = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
      let fromApp = (urlSegmentFromEnd == 'app') ? true : false;

      localStorage.setItem('interacted', 'Yes')
      if (!this.scriptLoaded && !fromApp) {
        this.pwaservice.setInitialScripts();


        if (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || currentUrl.includes("myphotos") || currentUrl.includes("myphotos")) {
          const scriptList = document.querySelectorAll("script[type='text/javascript']")

          //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
          const convertedNodeList = Array.from(scriptList)
          const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")

          if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
            scriptToRemove.parentNode.removeChild(scriptToRemove)
          }
        } else {
          // 
          this.pwaservice.manageTrustBadge()
        }

        this.scriptLoaded = true;
      }

    }



    //  setTimeout(() => {

    //  }, 500);

  }
  @HostListener('window:storage', ['$event'])
  onStorage(event: StorageEvent) {

    if (event.key === 'reload' && event.newValue) {
      // 
      setTimeout(() => {
        if (localStorage.getItem('userInfo') != null && localStorage.getItem('userInfo') != undefined && localStorage.getItem('userInfo') != '') {
          location.reload();
        } else {
          this.router.navigate(['/']).then(() => {
            location.reload();


          });
        }
      }, 1000);


    }
  }
  constructor(@Inject(DOCUMENT) private doc,
    private activeroute: ActivatedRoute,
    private router: Router, private utilityService: UtilityService, private translate: TranslateService, private route: Router, private renderer: Renderer2, private metaTagService: Meta, private titleService: Title,
    private globalService: GlobalService, private uploadService: UploadService, private dataService: DataService, private resolver: ComponentFactoryResolver, private contentfulService: ContentfulService, private modalService: NgbModal, private cdref: ChangeDetectorRef, public pwaservice: PwaService,
    private readonly ngxSmartBannerService: NgxSmartBannerService,
    private readonly viewContainerRef: ViewContainerRef, private readonly platform: Platform,) {
    var _this = this;
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // Page is visible (in focus)
        _this.handleFocus(_this);
      } else {
        // Page is not visible (out of focus)
        // You can handle this case if needed
      }
    });
    //
    // const urlParams = new URLSearchParams(window.location.search);
    //   const offlineApp = urlParams.get('offlineApp');
    //   if (offlineApp) {
    //     this.isOfflineApp = true;
    //   }

    // this.platform.ANDROID = true;

    this.ngxSmartBannerService.onClose.subscribe(() => {
      this.renderer.removeClass(document.body, 'smart-banner-open');
    });

    this.ngxSmartBannerService.onOpen.subscribe(() => {
      this.renderer.addClass(document.body, 'smart-banner-open');
    });
    var isAndroidMobile = navigator.userAgent.match(/Android/i);
    var domain = location.origin;
    var environmentFrom;
    var userLoggedIn, userId, userType;
    let logoPath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + environment[global.env].oemCode + "/logo-files/logo.svg";
    if (isAndroidMobile) {
      if (environment[global.env].ALLOW_SMART_BANNER == true) {
        let smartBannerResp: any = this.globalService.getSmartBannerContext();
        this.ngxSmartBannerService.initialize({
          viewContainerRef: this.viewContainerRef,
          price: smartBannerResp.price,
          buttonLabel: smartBannerResp.buttonLabel,
          priceSuffix: {
            android: smartBannerResp.priceSuffixAndroid,
          },
          icon: {
            android: logoPath,
          },
          buttonUrl: {
            android: environment[global.env].APP_URL,
          },
          daysHidden: 15,
          daysReminder: 90,
          hideRating: true,
          title: (environment[global.env].APP_URL.includes('fotokasten')) ? 'fotokasten express' : 'myphotobook express',
          author: (environment[global.env].APP_URL.includes('fotokasten')) ? 'fotokasten' : 'myphotobook',

          // enabledPlatforms:['Android']
        });
      }
      else {

      }
    }






    let isIosMobile = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i))
    if (isIosMobile && environment[global.env].iosAppId) {
      // this.metaTagService.addTags([
      //   { name: 'apple-itunes-app', content: 'app-id=284882215'+environment[global.env].iosAppId },

      // ]);
    }

    if (this.globalService.isUploadingFile) {
      localStorage.setItem('isUploadingFile', 'Yes')
    } else {
      localStorage.setItem('isUploadingFile', 'No')
    }

    if (navigator.userAgent.includes('didomi') || navigator.userAgent.includes('Didomi')) {
      localStorage.setItem('interacted', 'Yes')
      if (!this.scriptLoaded) {
        this.pwaservice.setInitialScripts();
        let currentUrl = window.location.href;

        if (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || currentUrl.includes("myphotos") || currentUrl.includes("myphotos")) {
          const scriptList = document.querySelectorAll("script[type='text/javascript']")

          //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
          const convertedNodeList = Array.from(scriptList)
          const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")

          if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
            scriptToRemove.parentNode.removeChild(scriptToRemove)
          }
        } else {
          // 
          this.pwaservice.manageTrustBadge()
        }

        this.scriptLoaded = true;
      }

    }

    this.translate.getTranslation('de').pipe(takeUntil(this.destroyed$)).subscribe(res => this.globalService.setLanguageData(res));
    let currentUrl = window.location.href;

    if (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order')) {
      setTimeout(() => {
        $("[id^='trustbadge-container']").addClass('hideBadge')

      }, 1500);


    } else {
      $("[id^='trustbadge-container']").removeClass('hideBadge');
      //  
    }
    //
    if (this.globalService.getHeaderData() == undefined) {
      this.dataService.loadHeaderMenu().pipe(takeUntil(this.destroyed$)).subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        let data = resJSON;
        for (var i = 0; i < resJSON.length; i++) {
          var categoryThumbURL;
          if (this.isMobileDevice) {
            categoryThumbURL = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].AMAZON_CATEGORY_FOLDER_PATH + "200/" + resJSON[i].thumb_image;
          }
          else {
            categoryThumbURL = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].AMAZON_CATEGORY_FOLDER_PATH + "300/" + resJSON[i].thumb_image;
          }

          resJSON[i].fullSrc = categoryThumbURL;
          resJSON[i].show = false;
        }
        let resSTR1 = JSON.stringify(data);
        let resJSON1 = JSON.parse(resSTR1);


        this.globalService.setHeaderData(resJSON1);


      }, (error) => {

      });
    }


    this.contentfulService.$updateAvailable.pipe(takeUntil(this.destroyed$)).subscribe(response => {

      if (response === true) {
        this.updateAvailable = true;
        localStorage.setItem('showLeavealertOnEditor', 'Yes')
      }
      else {
        this.updateAvailable = false;
      }

    });

    //For loading different versions of headers and footers //
    this.contentfulService.$headerData.pipe(takeUntil(this.destroyed$)).subscribe(response => {

      this.headerVersion = response.headerVersion;
      this.headerContentData = response.headerData;
      this.cdref.detectChanges();
      // this.renderer.addClass(document.body, 'Header-' + this.headerVersion);
      // 
      if (this.headerVersion == '1') {
        this.renderer.addClass(document.body, 'header-v1');
        this.renderer.removeClass(document.body, 'header-v2');
      } else {
        this.renderer.addClass(document.body, 'header-v2');
        this.renderer.removeClass(document.body, 'header-v1');
      }






      let currentUrl = window.location.href;
      let urlSegmentFromEnd = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
      let fromApp = (urlSegmentFromEnd == 'app') ? true : false;
      if (fromApp) {
        // localStorage.removeItem('inter')
        const scriptList = document.querySelectorAll("script[type='text/javascript']")

        //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
        const convertedNodeList = Array.from(scriptList)
        const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")

        if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
          scriptToRemove.parentNode.removeChild(scriptToRemove)
        }
        setTimeout(() => {
          $("[id^='trustbadge-container']").addClass('hideBadge')

        }, 100);
      }

    });
    this.contentfulService.$footerData.pipe(takeUntil(this.destroyed$)).subscribe(response => {

      // 
      this.footerVersion = response.footerVersion;
      this.footerContentData = response.footerData.fields;
      this.cdref.detectChanges();

    });




    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(event => {
      this.modifyHeader(event)

      localStorage.removeItem('previousProduct')

      let urlcheck = this.router.url;

      this.metaTagService.addTags([
        { name: 'viewport', content: 'width=device-width' },

      ]);
      this.handleFocus(_this);

      if ((urlcheck.includes("myphotos") || urlcheck.includes("myphotos") || urlcheck.includes("app/product")
        || urlcheck.includes("create/easy") || urlcheck.includes('fotoabzuege/bestellen') || urlcheck.includes('photo-prints/order'))) {
        this.isUploadRunning = false;
        $("#window-bar").hide();

        setTimeout(() => {
          this.metaTagService.updateTag(
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },

          );
        }, 10);

      } else {
        this.imageUploadCounter = 0;
        let per: any = 0;
        setTimeout(() => {
          this.metaTagService.updateTag(
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes' },

          );
        }, 10);

        this.imageUploadCounter = this.uploadService.imageUploadCounter
        this.total_files = this.uploadService.tempImageArray.length;
        // var per = (data.imageUploadCounter / data.totalFiles) * 100;
        per = Math.round((this.imageUploadCounter / this.total_files) * 100);
        let parsedValue = parseFloat(per);
        if (!isNaN(parsedValue)) {
          this.uploadProgressValue = per;
        }

        // 

        this.updateCircleProgress(per);
        // this.divProgressBar.nativeElement.style.width = per + "%";
        if (this.globalService.gettLanguageData() != undefined) {
          let languageData = this.globalService.gettLanguageData();
          this.uploadingMsg = this.imageUploadCounter + " / " + this.total_files + " " + languageData.web.ie_photo_upload_title

        }

        this.isUploadRunning = this.globalService.isUploadingFile
        if (this.isUploadRunning) {
          $("#window-bar").show();
        }

      }


      if (this.updateAvailable && !this.globalService.isUploadingFile) {
        document.location.reload()
      }
    }


    );



    //this.cdref.detectChanges();
    this.seoDataSubscription = this.globalService.getSeoMetaDataObservable().subscribe(
      data => {
        if (data) {
          // 
          const listOfLinks = document.querySelectorAll("link[rel='alternate']")
          const convertedNodeList = Array.from(listOfLinks)
          convertedNodeList.map(item => {
            this.doc.head.removeChild(item);
          })
          this.loadPageMetaData(data);
          this.contentfulService.emitFooterAtLast(false);
          this.noOfTimes++
          //  }

        }
      });


    this.uploadProgressValue = 0;
    this.uploadService.$uploadEmitter.subscribe(
      data => {
        if (data) {
          let urlcheck = this.router.url;
          if ((urlcheck.includes("myphotos") || urlcheck.includes("myphotos") || urlcheck.includes("app/product") || urlcheck.includes('fotos-hochladen')
            || urlcheck.includes("create/easy") || urlcheck.includes('fotoabzuege/bestellen') || urlcheck.includes('photo-prints/order')) || urlcheck.includes("fotos-hochladen?product")) {
            this.isUploadRunning = false;

          } else {
            this.isUploadRunning = true;

            if (data.type == 'preparing') {
              if (this.globalService.gettLanguageData() != undefined) {
                let languageData = this.globalService.gettLanguageData();
                this.uploadingMsg = languageData.web.ie_photo_upload_preparing

              }
              return;
            } else {
              let per: any = 0;
              this.imageUploadCounter = 0;
              // this.arrAlbumImages.push(data.imageObject);
              this.imageUploadCounter = data.imageUploadCounter;
              this.total_files = data.totalFiles;
              // var per = (data.imageUploadCounter / data.totalFiles) * 100;
              per = Math.round((this.imageUploadCounter / data.totalFiles) * 100);
              this.uploadProgressValue = per;
              this.updateCircleProgress(per);
              // this.divProgressBar.nativeElement.style.width = per + "%";
              if (this.globalService.gettLanguageData() != undefined) {
                let languageData = this.globalService.gettLanguageData();
                this.uploadingMsg = data.imageUploadCounter + " / " + data.totalFiles + " " + languageData.web.ie_photo_upload_title

                let selectedPhotoItem = languageData.web.photo_upload_sticky_title;
                let totalUploadingPhoto = selectedPhotoItem.replace('%d', data.imageUploadCounter + "/" + data.totalFiles);
                this.uploadingMsgTooltip = totalUploadingPhoto;

              }
              // this.uploadingMsg = data.imageUploadCounter + " / " + data.totalFiles + " "+ languageData.web.ie_uploading_bar_msg;

              if (this.imageUploadCounter == data.totalFiles) {

                setTimeout(() => {
                  localStorage.removeItem('arrToUpload');
                  this.globalService.isUploadingFile = false;
                }, 10);


                setTimeout(() => {
                  this.isUploadRunning = false;

                }, 500);

                // if (this.isDisplayConfirmModal) {
                //   this.isConfirmDisplayModal = true;
                //   this.loadModal('confirmUploadedModal');
                // }
                // else {
                //   let projectId = this.uploadInfo.albumId;
                //   this.getProjectImages(projectId);
                // }
              }
            }
          }



        }
      });

    this.popupCrossCheckmark = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/checkmark-cross-16.svg';


  }

  handleFocus(_this) {

    //console.info( localStorage.getItem('userInfo'))

    // The browser window has come into focus, indicating the laptop woke up
    // setTimeout(() => {

    //   let sessionpopup = document.getElementById('sessionpopup')
    //   if (sessionpopup == null && (
    //     localStorage.getItem('userInfo') == null ||
    //     localStorage.getItem('userInfo') == undefined ||
    //     localStorage.getItem('userInfo') == '')) {      
    //     var info = { activeObj: false, type: "SessionOut" };
    //     _this.globalService.setOpenSessionLogout(info);
    //     //

    //   }
    //   //  console.log('Laptop woke up');
    // }, 100);

// console.info(localStorage.getItem('userInfo'),'hererer')
    let sessionpopup = document.getElementById('sessionpopup')
    // console.info(sessionpopup)
    if (sessionpopup == null && (
      localStorage.getItem('userInfo') == null ||
      localStorage.getItem('userInfo') == undefined ||
      localStorage.getItem('userInfo') == '')
      &&
      (localStorage.getItem('session-terminated') == 'true')

    ) {
      //  var info = { activeObj: false, type: "SessionOut" };
      _this.sessionLogoutModal({ type: 'SessionOut' })
      //

    }
    // if (sessionpopup == null){
    //   if (localStorage.getItem('ng2Idle.lastactivity.doneat') != null && localStorage.getItem('ng2Idle.lastactivity.doneat') != undefined && localStorage.getItem('ng2Idle.lastactivity.doneat') != '') {
    //     let lastActivityAt: any = new Date(localStorage.getItem('ng2Idle.lastactivity.doneat'))//moment(localStorage.getItem('ng2Idle.lastactivity.at')).toDate();
    //     let currentDateTime: any = new Date();
    //     let differenceInSeconds = (currentDateTime - lastActivityAt) / 1000;
  
  
  
    //     // USER'S SESSION DETECTION STARTS
    //     console.info(differenceInSeconds , environment[global.env].userSessionTimeOutDuration)
    //     if (differenceInSeconds > environment[global.env].userSessionTimeOutDuration) {
    //       // 
    //       //If user reopens tab after TimeOut period
    //       const showPopup = true
    //       // this.idle.clearInterrupts();
    //       // this.idle.stop();
  
    //       localStorage.removeItem('ng2Idle.lastactivity.doneat');
    //       this.globalService.resetTimer(showPopup);
    //       return false;
  
  
    //     }
  
  
    //   }
    // }
 




    // console.log('Laptop woke up');
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    //localStorage.setItem('isUploadingFile', 'No')
    if (this.globalService.isUploadingFile) {
      $event.returnValue = true;
    } else {
      // 

    }
  }

  sessionLogoutModal(info) {
    let sessionpopup = document.getElementById('sessionpopup')

    if (sessionpopup == null) {
      // console.info('here')

      if (localStorage.getItem('ng2Idle.lastactivity.doneat') != null && localStorage.getItem('ng2Idle.lastactivity.doneat') != undefined && localStorage.getItem('ng2Idle.lastactivity.doneat') != '') {
        let lastActivityAt: any = new Date(localStorage.getItem('ng2Idle.lastactivity.doneat'))//moment(localStorage.getItem('ng2Idle.lastactivity.at')).toDate();
        let currentDateTime: any = new Date();
        let differenceInSeconds = (currentDateTime - lastActivityAt) / 1000;



        // USER'S SESSION DETECTION STARTS
      console.info(
        differenceInSeconds,
        environment[global.env].userSessionTimeOutDuration,
        environment[global.env].userSessionTerminationPopupDuration
      )
        if (differenceInSeconds > environment[global.env].userSessionTimeOutDuration) {
          // 
          //If user reopens tab after TimeOut period
          const showPopup = true
          // this.idle.clearInterrupts();
          // this.idle.stop();
          localStorage.clear()

          localStorage.removeItem('ng2Idle.lastactivity.doneat');
          this.globalService.resetTimer(showPopup);
          return false;


        }
        else{
          const modalRef = this.modalService.open(SessionlogoutComponent, { windowClass: 'sessionpopupzIndex', size: "sm", centered: true, backdrop: "static", keyboard: false });
          modalRef.componentInstance.type = info.type;
          modalRef.result.then(result => { }).catch(error => {
          });
        }


      }

     
    }



    if (sessionpopup != null) {
      setTimeout(() => {
        const isUserIsPresent = ((localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '')) ? false : true;
        if (!isUserIsPresent && localStorage.getItem('session-terminated') != null && localStorage.getItem('session-terminated') != undefined) {
          this.modalService.dismissAll();
          localStorage.setItem('reload', 'false');
  
          var myItem = localStorage.getItem('interacted');
          localStorage.removeItem('interacted');
          localStorage.setItem('interacted', myItem);
          localStorage.clear();
  
  
  
          this.globalService.setCartLengthData(0);
          this.router.navigate(['/']).then(() => {
            location.reload();
  
  
          });
  
        }
  
  
      }, environment[global.env].userSessionTerminationPopupDuration);
  
    }
   

  }

  updateCircleProgress(value) {
    if (this.globalService.isUploadingFile) {
      this.uploadProgressValue = 0;
      let parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        this.uploadProgressValue = value;
      }
      setTimeout(() => {
        //var value = 50;//$(this).attr('data-value');
        var left = $("#roundCircle").find('.progress-left .progress-bar');
        var right = $("#roundCircle").find('.progress-right .progress-bar');

        if (value == 0) {
          left.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
          right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
        }


        if (value > 0) {
          if (value <= 50) {
            right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
          }
          else {
            right.css('transform', 'rotate(180deg)')
            left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
          }
        }

        function percentageToDegrees(percentage) {
          return percentage / 100 * 360
        }
      }, 1);
      setTimeout(() => {
        //  
        if (value == 100) {
          this.clearUploadingVariables();
          var left = $("#roundCircle").find('.progress-left .progress-bar');
          var right = $("#roundCircle").find('.progress-right .progress-bar');
          left.css('transform', 'rotate(' + percentageToDegrees(0) + 'deg)')
          right.css('transform', 'rotate(' + percentageToDegrees(0) + 'deg)')
        }
      }, 1000);
      function percentageToDegrees(percentage) {
        return percentage / 100 * 360
      }
    }
    else {
      setTimeout(() => {
        this.clearUploadingVariables()
      }, 100);


    }
  }

  clearUploadingVariables() {

    this.isUploadRunning = false
    // this.imagePrepareCounter = 0;
    // this.showMyphotosUploadText = false;
    // this.photosUploadingStart = false;
    this.uploadProgressValue = 0;
    this.globalService.isUploadingFile = false;
  }

  //   checkUpdate(){
  //     this.appRef.isStable.subscribe((isStable)=>{
  //       if(isStable){
  //         const timeInterval=interval(30000)
  //         timeInterval.subscribe(() => {
  //           this.updates.checkForUpdate()
  //          // 
  //         })


  //     //     .then(() =>{
  //     //       //
  //     //     }

  //       }
  //     })

  //   }

  // updateClient(){
  //   if (!this.updates.isEnabled) {
  //     this.updates.available.subscribe(event => {
  //       // 
  //       // 
  //       
  //       this.promptUser(event);
  //     });

  //     

  //   }
  //   this.updates.available.subscribe(event => {
  //     // 
  //     // 
  //     
  //     this.promptUser(event);
  //   });

  // }
  private promptUser(e): void {
    if (e.availabl && this.contentfulService && this.contentfulService != null) {
      // 
      this.contentfulService.webAppupdateAvailable(true);

    } else {
      // 
      this.contentfulService.webAppupdateAvailable(false);

    }
  }
  ngOnInit(): void {
    this.activeroute.queryParams.subscribe(params => {
      let offlineAppParam = params['offlineApp'];
      if (offlineAppParam) {
        this.isOfflineApp = true;
      }
      // let isbanner:any = params['isbanner'];
      // let sessionId:any = params['sessionId'];
      // console.log('isbanner==>',isbanner);
      // setTimeout(() => {
      //   if (isbanner) {
      //     this.isbanner = false;
      //     console.log('global.lang',global.env);
      //     params = {
      //       offlineApp: true,
      //       sessionId: sessionId
      //     };
      //     console.log('environment[global.lang].DOMAIN',environment[global.env]);
          
      //     // this.router.navigateByUrl(environment[global.env].DOMAIN)
      //     this.router.navigate(['/'], { queryParams:params }).then(result => {
      //       console.log('currrentUrl==>',this.router.url);
            
      //       // console.log('Navigation result:', result);
      //     });
      //   }
      // }, 30);
      
    });
    // console.log('this.isbanner = isbanner;==>',this.isbanner);
    
    var _this = this;
    window.addEventListener('message', function (event) {
      let type: any;
      let sessionId: any;
      if (event.data === 'clearLocalStorage') {
        localStorage.clear();
        console.info('Iframe localStorage cleared.');
      }
      let offlineInfo = event.data.info;
      if (offlineInfo) {
        type = offlineInfo.type;
        sessionId = offlineInfo.sessionId;
      }
      let baseUrl = environment[global.env].DOMAIN;
      // let baseUrl = 'http://localhost:4700';
      if (offlineInfo && offlineInfo.offlineApp) {
        let params: any = {};
        if (offlineInfo.pageCode) {
          params = {
            offlineApp: true,
            sessionId: sessionId,
            pageCode: offlineInfo.pageCode
          };
          // console.log('type',type);

          // _this.router.navigate([type], { queryParams: params }).then(result => {
          //   console.log('Navigation result:', result);
          // });
        }
        else {
          params = {
            offlineApp: true,
            sessionId: sessionId
          };
        }
        // setTimeout(() => {
        console.log('type==>',type);
        
          _this.router.navigate([type], { queryParams: params }).then(result => {
            // console.log('Navigation result:', result);
          });
        // }, 1000);
      
        // Get the host (window.location.host includes hostname and port)
        // const host = window.location.host;

        // Get the protocol (http or https)
        // const protocol = window.location.protocol;
        // _this.router.navigateByUrl(actualUrl)
        // Build the full URL
        // const fullUrl = `${protocol}//${host}${path}`;
        // console.log('fullUrl===>',fullUrl);
        // console.log('actualUrl==>',actualUrl);
        //       _this.router.navigate([actualUrl]).then(result => {
        // console.log('result==>',result);

        //         //  window.location.reload()
        //          });
        // }
      }
    });

    //     setTimeout(() => {
    //       if (iosAppId) {

    //         this.metaTagService.addTag({
    //           name: 'smartbanner:title',
    //           content: 'fotokasten express'
    //         });
    //         this.metaTagService.addTag({
    //           name: 'smartbanner:author',
    //           content: 'fotokasten'
    //         });
    //         this.metaTagService.addTag({
    //           name: 'smartbanner:price',
    //           content: 'FREE'
    //         });
    //         this.metaTagService.addTag({
    //           name: 'smartbanner:price-suffix-apple',
    //           content: 'Smart Application'
    //         });
    //         this.metaTagService.addTag({
    //           name: 'smartbanner:icon-apple',
    //           content: '- On the App Store'
    //         });

    //         this.metaTagService.addTag({
    //           name: 'smartbanner:icon-apple',
    //           content: '- In Google Play'
    //         });

    //         this.metaTagService.addTag({
    //           name: 'smartbanner:button',
    //           content: 'VIEW'
    //         });
    //         this.metaTagService.addTag({
    //           name: 'smartbanner:button-url-apple',
    //           content: 'https://ios/application-url'
    //         });

    //         this.metaTagService.addTag({
    //           name: 'smartbanner:enabled-platforms',
    //           content: 'android,ios'
    //         });

    //         this.metaTagService.addTag({
    //           name: 'smartbanner:close-label',
    //           content: 'Close'
    //         });



    // //         <meta name="smartbanner:title" content="Smart Application">
    // // <meta name="smartbanner:author" content="SmartBanner Contributors">
    // // <meta name="smartbanner:price" content="FREE">
    // // <meta name="smartbanner:price-suffix-apple" content=" - On the App Store">
    // // <meta name="smartbanner:price-suffix-google" content=" - In Google Play">
    // // <meta name="smartbanner:icon-apple" content="https://url/to/apple-store-icon.png">
    // // <meta name="smartbanner:icon-google" content="https://url/to/google-play-icon.png">
    // // <meta name="smartbanner:button" content="VIEW">
    // // <meta name="smartbanner:button-url-apple" content="https://ios/application-url">
    // // <meta name="smartbanner:button-url-google" content="https://android/application-url">
    // // <meta name="smartbanner:enabled-platforms" content="android,ios">
    // // <meta name="smartbanner:close-label" content="Close">



    //         // 
    //          var s = document.createElement('script');
    //          (s.async = true), (s.type = 'text/javascript');
    //          s.src = 'https://myphotobook.omq.de/help/help.min.js';
    //          this.smartbannerScriptDiv.nativeElement.appendChild(s);
    //         // 
    //        }
    //     }, 1000);


    //     let isIosMobile = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i))

    //     if (isIosMobile && environment[global.env].iosAppId) {
    //       // 
    //       //  this.metaTagService.removeTag("name='apple-itunes-app'");

    //       setTimeout(() => {
    //         // //
    //         // this.metaTagService.addTags([
    //         //   { name: 'apple-itunes-app', content: 'app-id=' + environment[global.env].iosAppId },

    //         // ]);
    //       }, 100);


    //     }
    var isMobileDevice = this.utilityService.detect();
    if (isMobileDevice) {
      environment[global.env].PROJECT_THUMB = "fit-in/100x100/" + environment[global.env].PROJECT_NORMAL;
    }
    else {
      environment[global.env].PROJECT_THUMB = "fit-in/150x150/" + environment[global.env].PROJECT_NORMAL;
    }

    this.DesignGroup = environment[global.env].ROOT_CLASS;
    this.renderer.addClass(document.body, 'Class-for-' + this.DesignGroup);
    this.setTheme(this.ThemeCollections[this.DesignGroup]);


    if (this.DesignGroup == 'FS') {
      this.title = 'FotoSystem | beflügelt deine Kreativität';

    } else if (this.DesignGroup == 'FTK') {
      this.title = 'fotokasten';
    }
    else if (this.DesignGroup == 'MPB') {
      this.title = 'myphotobook';
    }

    this.titleService.setTitle(this.title);
    this.renderer.setAttribute(document.querySelector('html'), 'lang', global.lang);

    // 
    // if(localStorage.getItem('pwaConfirmation')==null || localStorage.getItem('pwaConfirmation')==undefined || localStorage.getItem('pwaConfirmation')==''){
    //   this.contentfulService.openPwaConfirmation();
    // }

    // this.modalRef = this.modalService.open(this.openPwaModalRef, { size: 'md', windowClass: 'md', centered: true, backdrop: 'static', keyboard: false });
  }

  loadModal(id) {
    $(document).ready(function () {
      $('#' + id).modal('show');
    })
  }
  ngOnChanges() {

    //  this.cdref.detectChanges();

  }
  modifyHeader(location) {



    var url = location.url;
    let arrUrl = url.split('/');

    url = arrUrl[1];
    //


    // this.metaTagService.addTags([
    //   { name: 'viewport', content: 'width=device-width' },

    // ]);

    // if (url.includes('create/easy') || url.includes('app/product') || url.includes('fotoabzuege/bestellen') || url.includes('photo-prints/order' || url.includes("myphotos") )) {

    //   setTimeout(() => {
    //     this.metaTagService.updateTag(
    //       { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },

    //     );
    //   }, 100);

    // } else {
    //   setTimeout(() => {
    //     this.metaTagService.updateTag(
    //       { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes' },

    //     );
    //   }, 100);
    // }

    if (url.includes('404') && url == '404') {
      this.metaTagService.addTags([
        { name: 'prerender-status-code', content: '404' },

      ]);
    } else {
      this.metaTagService.removeTag("name='prerender-status-code'");
    }

    // this.metaTagService.addTags([
    //   { name: 'robots', content: indexfolow },
    //   { name: 'description', content: seoData.metaDescription },
    //   { name: 'keywords', content: seoData.metaKeywords },
    // ]);

    var url1 = arrUrl[1] + '/' + arrUrl[2];
    if (url === 'create' || url === 'app' || url == 'preview') {
      this.showFooter = false;
      this.showHeader = false;
    } else {
      this.showFooter = true;
      this.showHeader = true;
    }
    if (url1 == 'fotoabzuege/bestellen' || url === 'fotoabzuege') {
      this.showFooter = false;
    }

    // this.cdref.detectChanges();
  }

  gotoNextPage() {
    // 
    if (this.uploadInfo.action == "inpage-editor") {
      this.isConfirmDisplayModal = false;
      let url = 'app/product/' + this.uploadInfo.productCode + '/' + this.uploadInfo.albumId;
      this.route.navigate([url])
      window.scrollTo(0, 0);

    }
    else if (this.uploadInfo.action == "photolist") {
      this.getProjectImages(this.uploadInfo.albumId);
    }
  }

  getProjectImages(albumId) {

    this.dataService.getProjectImages(albumId).subscribe((res: Response) => {
      // this.selectPhotoCounter = 0;
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      var arrImage = resJSON.images;
      var manualSequence = resJSON.imagesSequence;

      for (var key in arrImage) {
        if (arrImage[key].imageAlbumCode) {
          var imagePath = this.utilityService.getImagePath(arrImage[key].imageAlbumCode);
          arrImage[key].thumbSrc = imagePath.thumbPath + '' + arrImage[key].Image;
          arrImage[key].fullSrc = imagePath.fullPath + '' + arrImage[key].Image;
        }
        arrImage[key].isRecent = false;
        arrImage[key].usedCounter = 0;
        arrImage[key].imageAlbumCode = arrImage[key].imageAlbumCode;
        arrImage[key].owner = 'user';
        arrImage[key].imageHeight = arrImage[key].ImageHeight;
        arrImage[key].imageWidth = arrImage[key].ImageWidth;
        arrImage[key].file = "";
        arrImage[key].isUploaded = true;
        arrImage[key].isSelected = false;
        arrImage[key].RotationAngle = parseInt(arrImage[key].RotationAngle);
        arrImage[key].isSkip = true;
        arrImage[key].filterToHide = false;
        arrImage[key].photoAsbackground = false;
        arrImage[key].isCover = false;
        arrImage[key].backCover = 0;
        arrImage[key].frontCover = 0;
        arrImage[key].type = 'image';

        this.arrAlbumImages.push(arrImage[key])
      }
      this.addImageToEditor(albumId);

    },
      err => this.addImageToEditor(albumId));
  }

  addImageToEditor(projectId) {
    var arrPhotoToSave = {};
    for (var i = 0; i < this.arrAlbumImages.length; i++) {
      arrPhotoToSave[this.arrAlbumImages[i].Image] = this.arrAlbumImages[i];
    }

    var imagesSequence = [];
    var projectImageData = { albumName: this.projectName, images: arrPhotoToSave, imagesSequence: imagesSequence };
    this.dataService.setAlbumImage(projectId, JSON.stringify(projectImageData)).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        window.scrollTo(0, 0);
        let arrUrl = this.route.url.split('/');
        if (arrUrl[1] === 'myphotos') {
          window.location.reload();
        }
        else {
          this.isConfirmDisplayModal = false;
          this.route.navigate(['myphotos/' + projectId]);
        }
      }
    });
  }

  loadPageMetaData(seodata) {
    let seoData = seodata;
    // 
    //
    this.titleService.setTitle(seoData.pageTitle);
    let attributeSelector: string = 'name="description"';
    let attributeSelect: string = 'name="keywords"';
    let attributeindexfollow: string = 'name="robots"';
    this.metaTagService.removeTag(attributeSelector);
    this.metaTagService.removeTag(attributeSelect);
    this.metaTagService.removeTag(attributeindexfollow);
    // if(seoData.robotMetaDirective){
    let indexfolow = (seoData.robotMetaDirective) ? seoData.robotMetaDirective.toLowerCase() : '';

    this.metaTagService.addTags([
      { name: 'robots', content: indexfolow },
      { name: 'description', content: seoData.metaDescription },
      { name: 'keywords', content: seoData.metaKeywords },
    ]);
    // }
    var elementCanonical: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`);
    var elementENlang: HTMLLinkElement = this.doc.querySelector(`link[hreflang='en']`);
    var elementDElang: HTMLLinkElement = this.doc.querySelector(`link[hreflang='de']`);
    var elementDefault: HTMLLinkElement = this.doc.querySelector(`link[hreflang='x-default']`);

    if (elementCanonical != null) {
      this.doc.head.removeChild(elementCanonical);
    }
    if (elementENlang != null) {
      this.doc.head.removeChild(elementENlang);
    }
    if (elementDElang != null) {
      this.doc.head.removeChild(elementDElang);
    }
    if (elementDefault != null) {
      this.doc.head.removeChild(elementDefault);
    }

    if (seoData && seoData.canonicalUrl != undefined && seoData.canonicalUrl != null && seoData.canonicalUrl != '') {

      let canonicalUrlSegment;
      if (seoData.canonicalUrl.charAt(0) === '/') {
        canonicalUrlSegment = seoData.canonicalUrl.substring(1);
      } else {
        canonicalUrlSegment = seoData.canonicalUrl;
      }
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      // link.setAttribute('href', this.doc.URL);
      link.setAttribute('href', location.origin + '/' + canonicalUrlSegment);
    }
    // this.doc.head.add
    // if (seoData.hrefFirst != '') {
    //     let link: HTMLLinkElement = this.doc.createElement('link');
    //     link.setAttribute('rel', 'alternate');
    //     link.setAttribute('hreflang', 'en');
    //     this.doc.head.appendChild(link);
    //     link.setAttribute('href', seoData.hrefFirst);
    // }
    // if (seoData.hrefSecond != '') {
    //     let link: HTMLLinkElement = this.doc.createElement('link');
    //     link.setAttribute('rel', 'alternate');
    //     link.setAttribute('hreflang', 'de');
    //     this.doc.head.appendChild(link);
    //     link.setAttribute('href', seoData.hrefSecond);
    // }
    // if (seoData.hrefFifth != '') {
    //     let link: HTMLLinkElement = this.doc.createElement('link');
    //     link.setAttribute('rel', 'alternate');
    //     link.setAttribute('hreflang', 'x-default');
    //     this.doc.head.appendChild(link);
    //     link.setAttribute('href', seoData.hrefFifth);
    // }
    if (seoData.hrefLang) {
      seoData.hrefLang.map(item => {
        // 
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', item.rel);
        link.setAttribute('href', item.href);
        link.setAttribute('hreflang', item.hrefLang);
        this.doc.head.appendChild(link);
      })
    }
  }
  setTheme(theme: {}) {
    Object.keys(theme).forEach(k => {
      document.documentElement.style.setProperty(`--${k}`, theme[k])
      if (k == 'font-url') {

        document.documentElement.style.setProperty(`--${k}`, environment[global.env].RESOURCE_URL_AMAZON)
      }
    }

    );
  }

  closeUploadBar() {
    $("#window-bar").hide();
    this.isUploadRunning = false
  }

}
