import { BrowserModule, Title, HammerGestureConfig, HAMMER_GESTURE_CONFIG, provideClientHydration } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, NgModule, inject, provideAppInitializer } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import ngx-translate and the http loader
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';


// import ngx-translate-messageformat-compiler
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CustomTranslateLoader } from './trans.loader';

import * as Hammer from 'hammerjs';

// import { NgImageSliderModule } from 'ng-image-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContentfulService } from './services/contentful.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF } from '@angular/common';
import { AppConfigService } from './services/app-config.service';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UserModule } from './user/user.module';
import { SessionlogoutComponent } from './sessionlogout/sessionlogout.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxPayPalModule } from 'ngx-paypal';
import { ConfirmpromtboxComponent } from './guard/confirmpromtbox/confirmpromtbox.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from 'src/app/services/pwa.service';
import { FscHeaderV1Component } from './shared/fsc-header-v1/fsc-header-v1.component';
import { FscFooterV1Component } from './shared/fsc-footer-v1/fsc-footer-v1.component';

import { ElHeaderV1Component } from './shared/EL-Header-1/EL-Header-1.component';
import { ElHeaderV2Component } from './shared/EL-Header-2/EL-Header-2.component';
import { MPBHeaderv1Component } from './shared/mpb-headerv1/mpb-headerv1.component';
import { ELFooterV1Component } from './shared/EL-Footer-1/EL-Footer-1.component';
import { MpbHeaderv2Component } from './shared/mpb-headerv2/mpb-headerv2.component';
import { DesignSelectorComponent } from './design-selector/design-selector.component';
// import * as Sentry from '@sentry/angular-ivy';
import * as Sentry from '@sentry/angular';
import { GlobalErrorHandler } from './services/global-error-handler';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';
// import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeComponent } from 'angularx-qrcode';

import { RouterModule } from '@angular/router';
import { SvgIconModule } from 'src/lib/svg-icon.module';
const initializer = (pwaService: PwaService, traceService: Sentry.TraceService) => () => pwaService.initPwaPrompt();
export function TranslateMessageFormatCompilerFactory() {
  return new TranslateMessageFormatCompiler();
}

//const config: SocketIoConfig = { url: 'http://localhost:5000', options: { transports : ['websocket'] } };

// @Injectable({ providedIn: 'root' })
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({ 
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SessionlogoutComponent,
        ConfirmpromtboxComponent,
         FscHeaderV1Component,
        FscFooterV1Component,
        ElHeaderV1Component,
        ElHeaderV2Component,
        ELFooterV1Component,
        MPBHeaderv1Component,
        MpbHeaderv2Component,
        DesignSelectorComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

    /* No need this line anymore in Angular CLI 19, as this 
     happens because withServerTransition() was removed in Angular 17+ when Angular switched to Hydration for SSR instead of server transitions. */
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    imports: [
        QRCodeComponent,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        UserModule,
        AppRoutingModule,
        SvgIconModule,
        // NgImageSliderModule,
        LazyLoadImageModule,
        NgbModule,
        HammerModule,
        BrowserAnimationsModule,
        NgxSmartBannerModule,
        NgxPayPalModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true,
        }),
        ToastrModule.forRoot({ preventDuplicates: true, closeButton: true }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // useFactory: HttpLoaderFactory,
                useClass: CustomTranslateLoader,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                //useClass: TranslateMessageFormatCompiler
                useFactory: TranslateMessageFormatCompilerFactory
            },
            defaultLanguage: 'de'
        }),
        NgIdleKeepaliveModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerImmediately' //'registerWhenStable:30000'
        }),
        
        
    ],
    providers: [
        provideClientHydration(),
        Title, 
        ContentfulService, 
        NgbActiveModal,
        {
            provide: APP_BASE_HREF,
            useFactory: (config: AppConfigService) => {
                var appBaseHref = config.get('appBaseHref');
                return appBaseHref;
            },
            deps: [AppConfigService]
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => {
            const pwaService = inject(PwaService);
            const traceService = inject(Sentry.TraceService);
            initializer(pwaService, traceService);
        // const initializerFn = (initializer)(inject(PwaService), inject(Sentry.TraceService));
        // return initializerFn();
        }),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] 
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

