import { Component, Input, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from 'src/app/services/contentful.service';
@Component({
    selector: 'app-fsc-footer-v1',
    templateUrl: './fsc-footer-v1.component.html',
    styleUrls: ['./fsc-footer-v1.component.scss'],
    host: { '[id]': 'componentId' },
    standalone: false
})
export class FscFooterV1Component implements OnInit {
  @Input()
  footerData: any;
  footerContent: any = [];

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
   // 
    this.loadFooter()
  }


  loadFooter() {
    if (this.footerData) {
      this.footerContent = [];
      let titleIndex = 0;
      let indexvalue = 0;
      for (var key in this.footerData) {
        if ((typeof (this.footerData[key]) == 'string' && key != 'internalName' && key != 'selectDesign') || key == 'colLogoAddress') {

          if (key == 'colLogoAddress') {

            let companyAddress = documentToHtmlString(this.footerData[key].fields.companyAddress);
            companyAddress = this.contentfulService.removeBlankTags(companyAddress);

            this.footerContent.push({
              'key': key, 'title': this.footerData[key], data: companyAddress
              , hideTitle: true
            })
          } else {
            this.footerContent.push({ 'key': key, 'title': this.footerData[key], data: [] })
          }

        }


        // if (typeof (this.footerData[key]) == 'object') {
        //   titleIndex++;
        //   if(this.footerContent.findIndex(subItems => subItems.key == 'col' + titleIndex + 'Title')<0){
        //     this.footerContent.push({ 'key': 'col'+titleIndex+'Title', 'title': 'col'+titleIndex+'Title', data: [],hideTitle:true })
        //   }
        //   
        // }


      }
     // 
      for (var key in this.footerData) {
        if (typeof (this.footerData[key]) == 'object' && key!='colLogoAddress') {
          indexvalue++
          let columnIndex = this.footerContent.findIndex(subItems => (subItems.key == 'col' + indexvalue + 'Title'))
          if (columnIndex > -1) {
            this.footerData[key].map(itemcolumns => {
             // 
              this.footerContent[columnIndex].data.push(itemcolumns.fields)
            })

          }
        }

      }
     
      this.footerContent.map(item=>{

        if(item.hideTitle){
          // item.linkArray=item.data.filter(innerItem=>!innerItem.link)
          // item.textArray=item.data.filter(innerItem=>innerItem.link)
        }
        else{
          item.imagesArray=item.data.filter(innerItem=>innerItem.icon)
          item.textArray=item.data.filter(innerItem=>!innerItem.icon)
        

          item.textArray.map(item=>{
            if(window['countrySlugRequired']){
              let pathArray = location.pathname.split('/');
              let urlToAdd = pathArray[1] + '/' + pathArray[2] + '/';
              if(!item.link.includes('https') && !item.link.includes('www')){
                item.link=urlToAdd+item.link
              }
             
            }
            //item.link
          })
         // 
        }
      })




    }

    

  }

}
