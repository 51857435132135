<ng-container #footerContainer>

    <footer class="container" *ngIf="footerData && showFooter">

        <div class="news-letter-subs">
            <div class="news-letter-subs-details">
                <img src="{{newsletterIcon}}" class="news-letter-subs-logo" alt="" title="{{'web.footer_nl_section_image_tooltip' | translate}}">
                <!-- <img src="https://cdn.sw.fit/FS-AT/icons/newsletter_icon.svg" class="news-letter-subs-logo" alt="" title="{{'web.footer_nl_section_image_tooltip' | translate}}"> -->
                <div class="title fw-bold">{{'web.footer_nl_section_heading' | translate}}</div>
                <!-- <div class="sub-title d-sm-none d-block" [innerHtml]="nlPrivacyLink"></div> -->
            </div>
            <div class="news-letter-subs-form">
                <form class="mt-4" #newsletterForm="ngForm" name="form" (ngSubmit)="newsLetter(userEmail,newsletterForm)">
                    <div class="form-group d-sm-flex">
                        <input class="form-control text-center mb-0" id="{{newsletterId}}" type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" #email="ngModel" [(ngModel)]="userEmail" (keyup)="onKey()" name="nf-user" placeholder="{{'web.footer_nl_section_field_placeholder_txt' | translate}}"
                            autocomplete="email" required>
                        <button class="btn btn-light btn-md bg-warning text-secondary fw-bold mb-0">{{'web.footer_nl_section_cta_txt' | translate}} </button>
                    </div>
                    <div [hidden]="email.valid || email.pristine || email.errors?.required">
                        <span [hidden]="!email.hasError('pattern')" class="d-block text-center text-white pt-2">  {{'web.newsletter_subscribe_invalid_email' | translate}}</span>
                    </div>
                    <span class="d-block text-center text-white pt-2" *ngIf="emailAlredyExist && emailAlredyExist.message">
                        {{emailAlredyExist?.message}}
                    </span>
                </form>
                <!-- <form [formGroup]="NewsletterForm" action="">
                    <div class="form-group d-sm-flex">
                        <input type="email" formControlName="email" class="text-center mb-0" placeholder="{{'web.footer_nl_section_field_placeholder_txt' | translate}}" (keyup)="onKey()">
                        <button class="btn btn-light btn-md bg-warning text-secondary fw-bold mb-0" (click)="newsLetter()">{{'web.footer_nl_section_cta_txt' | translate}} </button>
                    </div>
                    <span class="d-block text-center text-white pt-2" *ngIf="NewsletterForm.hasError('email', 'email')">
                {{'web.newsletter_subscribe_invalid_email' | translate}}
            </span>
            <span class="d-block text-center text-white pt-2" *ngIf="emailAlredyExist && emailAlredyExist.message">
                {{emailAlredyExist?.message}}
            </span>
                </form> -->
                <div>
                    <p class="caption text-white" *ngIf="showErrorMessage">{{'web.newsletter_subscribe_no_email' | translate}}</p>
                </div>
                <div class="sub-title" [innerHtml]="nlPrivacyLink"></div>
                <!-- <p class="text-white d-none d-sm-block dataTitle">Information zum <a href=""><u>Datenschutz</u></a></p> -->
            </div>
        </div>

        <section class="d-sm-flex justify-content-sm-between">
            <a class="text-center border-bottom d-block d-sm-none cursor-pointer pt-3 pb-2 mobile-title-collapse" data-bs-toggle="collapse" href="#aboutCompany" role="button" aria-expanded="false" aria-controls="aboutCompany">Kontakt <img src="https://cdn.sw.fit/FS-AT/icons/arrow-down-16.svg" class="ms-1" alt=""></a>
            <div class="collapse about" id="aboutCompany">
                <img src="{{footerData.coLogo}}" alt="{{footerData.colLogoAddress.fields.coLogo.fields.description}}" class="footer-logo" (click)="gotoNextPage('/')" title="{{footerData.colLogoAddress.fields.coLogo.fields.title}}" width="{{footerData.colLogoAddress.fields.coLogo.fields.file.details.image.width}}"
                    height="{{footerData.colLogoAddress.fields.coLogo.fields.file.details.image.height}}">
                <span class="blink" *ngIf="updateAvailable">  .</span>
                <div class="details" [innerHTML]="footerData.companyAddress"></div>
            </div>
            <div class="footer-menu d-sm-flex justify-content-sm-between">
                <div class="footer-menu-box" *ngIf="footerData['col1']">
                    <div class="title d-none d-sm-block">{{footerData['col1Title']}}</div>
                    <a class="fw-normal text-center border-bottom d-block d-sm-none cursor-pointer py-2 mobile-title-collapse" data-bs-toggle="collapse" href="#col1" role="button" aria-expanded="false" aria-controls="col1">{{footerData['col1Title']}} <img src="https://cdn.sw.fit/FS-AT/icons/arrow-down-16.svg" class="ms-1" alt=""></a>
                    <div class="collapse list" id="col1">
                        <a class="d-block" (click)="gotoNextPage(item.fields.link)" *ngFor="let item of footerData['col1'];"> {{item.fields.label}} </a>
                    </div>
                </div>
                <div class="footer-menu-box" *ngIf="footerData['col2']">
                    <div class="title d-none d-sm-block">{{footerData['col2Title']}}</div>
                    <a class="fw-normal text-center border-bottom d-block d-sm-none cursor-pointer py-2 mobile-title-collapse" data-bs-toggle="collapse" href="#col2" role="button" aria-expanded="false" aria-controls="col2">{{footerData['col2Title']}} <img src="https://cdn.sw.fit/FS-AT/icons/arrow-down-16.svg" class="ms-1" alt=""></a>
                    <div class="collapse list" id="col2">
                        <a class="d-block" (click)="gotoNextPage(item.fields.link)" *ngFor="let item of footerData['col2'];"> {{item.fields.label}} </a>
                    </div>
                </div>
                <div class="footer-menu-box" *ngIf="footerData['col3']">
                    <div class="title d-none d-sm-block">{{footerData['col3Title']}}</div>
                    <a class="fw-normal text-center border-bottom d-block d-sm-none cursor-pointer py-2 mobile-title-collapse" data-bs-toggle="collapse" href="#col3" role="button" aria-expanded="false" aria-controls="col3">{{footerData['col3Title']}} <img src="https://cdn.sw.fit/FS-AT/icons/arrow-down-16.svg" class="ms-1" alt=""></a>
                    <div class="collapse list" id="col3">
                        <a class="d-block" (click)="gotoNextPage(item.fields.link)" *ngFor="let item of footerData['col3'];"> {{item.fields.label}} </a>
                    </div>
                </div>
            </div>
        </section>

        <div class="d-sm-flex justify-content-sm-between footer-feature-menu">
            <div class="d-flex flex-column text-center footer-feature-box box-col-5" *ngIf="footerData['col5']">
                <div class="title">{{footerData['col5Title']}}</div>
                <a class="d-block mt-auto cursor-pointer" (click)="gotoNextPagePayment(item.fields.link)" *ngFor="let item of footerData['col5'];">
                    <img alt="{{item.fields.description}}" src="{{item.fields.file.url}}" width="{{item.fields.file.details.image.width}}" height="{{item.fields.file.details.image.height}}" title="{{item.fields.title}}">
                </a>
            </div>
            <div class="d-flex flex-column text-center footer-feature-box box-col-6" *ngIf="footerData['col6']">
                <div class="title">{{footerData['col6Title']}}</div>
                <a class="d-block mt-auto cursor-pointer" (click)="gotoNextPageShipping(item.fields.link)" *ngFor="let item of footerData['col6'];">
                    <img alt="{{item.fields.description}}" src="{{item.fields.file.url}}" width="{{item.fields.file.details.image.width}}" height="{{item.fields.file.details.image.height}}" title="{{item.fields.title}}">
                </a>
            </div>
            <div class="d-flex flex-column text-center footer-feature-box box-col-7" *ngIf="footerData['col7']">
                <div class="title">{{footerData['col7Title']}}</div>
                <a class="d-block mt-auto cursor-pointer" (click)="gotoNextPage(item.fields.link)" *ngFor="let item of footerData['col7'];">
                    <img alt="{{item.fields.description}}" src="{{item.fields.file.url}}" width="{{item.fields.file.details.image.width}}" height="{{item.fields.file.details.image.height}}" title="{{item.fields.title}}">
                </a>
            </div>
        </div>

        <div class="d-sm-flex justify-content-sm-between align-items-sm-center privacy">
            <div class="copyRight">{{footerData.col4Title}}</div>
            <div class="privacy-menu" *ngIf="footerData.col4">
                <a *ngFor="let item of footerData.col4" (click)="gotoNextPage(item.fields.link)">{{item.fields.label}}</a>
            </div>
        </div>

        <div class="back-top-btn" id="backToTop" #backToTopButton>
            <div class="btn-top" (click)="scrollToTop()">
                <img src="../../assets/images/top-icon.png" alt="" title="">
            </div>
        </div>


    </footer>
</ng-container>