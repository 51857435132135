<div id="cd-small-header" #headerDiv>
    <div [hidden]="!showHeadersection">
        <ng-container #headerWidget></ng-container>
        <nav class="navbar navbar-expand-lg position-relative">
            <button class="border-0 bg-transparent navbar-toggler px-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="mobileHamburger()">
            <img src="{{hamburgerMenu}}" alt="" title="" width="24" height="24">
            </button>

            <a class="navbar-brand d-none d-lg-inline landscape-logo" routerLink="/">
                <img src="{{headerLogo}}" alt="" width="75" height="41" title="{{headerData.fields.logo.fields.title}}" alt="{{headerData.fields.logo.fields.description}}">
            </a>

            <div class="collapse navbar-collapse justify-content-sm-end" id="navbarSupportedContent">
                <ul class="navbar-nav" id="menuAccordion">

                    <!-- Sidebar logo start -->
                    <li class="bg-light p-2 d-flex align-items-center justify-content-between d-lg-none">
                        <a class="py-0 mx-auto" routerLink="/">
                            <img src="{{headerLogo}}" alt="" width="75" height="41" (click)="closeSidePanel()" title="{{'web.website_logo_name_tooltip' | translate}}">
                        </a>
                        <img src="{{imageIconPath}}checkmark-cross-32.svg" (click)="closeSidePanel()" alt="close">
                    </li>
                    <!-- Sidebar logo end -->

                    <li class="nav-item position-relative d-lg-flex align-items-center" [routerLinkActive]="['active']" *ngFor="let category of categoryData; let i = index" id="menuParent{{i}}">
                        
                        <span class="d-flex align-items-center justify-content-between">
                            <a class="nav-link" [routerLink]="category.categoryUrl" (click)="closeSidePanel()" [ngStyle]="{'color': category.breadcrumb_text}" style="--mainMenuHoverColor: {{category.more_info}};white-space: nowrap;">{{category.labelText}}</a>
                            <img src="{{imageIconPath}}arrow-right-16.svg" *ngIf="category.dropDownItems" alt="" class="d-lg-none d-inline-block me-3 arrowLabel" data-bs-toggle="collapse" [attr.data-bs-target]="'#menuItem'+ i" (click)="checkExpandCollapse(i)">
                        </span>
                            
                        <span class="badge badge-danger" (click)="gotoPrints($event)" *ngIf="category.preview=='PRINT_PREVIEW' && category.printCounts && category.printCounts>0">{{category.printCounts}}</span>
                        <span class="top-tooltip mt-2">{{'web.prints_photo_count_badge_tooltip' | translate}}</span>
                        

                        <div class="submenu-container collapse" id="menuItem{{i}}" data-parent="#menuAccordion" *ngIf="category.dropDownItems">

                            <div class="row m-0">
                                <div class="col accordion" *ngFor="let item of category.dropDownItems let i = index" id="subMenuAccordion">
                                    
                                    <span class="border-bottom d-flex align-items-center justify-content-between">
                                        <h4 class="title mb-0"><span class="title d-lg-block d-inline-block">{{item.title}}</span></h4>
                                        <img src="{{imageIconPath}}arrow-right-16.svg" alt="" class="d-lg-none d-inline-block arrowLabel" data-bs-toggle="collapse" [attr.data-bs-target]="'#subMenuItem'+ i">
                                    </span>

                                    <ng-container *ngIf="item.data">
                                        <ul class="ps-0 collapse" id="subMenuItem{{i}}" data-parent="#subMenuAccordion">
                                            <li *ngFor="let menuItem of item.data" (click)="closeSidePanel()">
                                                <a *ngIf = "menuItem.queryVariable==''" routerLink="{{menuItem.link}}">{{menuItem.label}}</a>
                                                <a *ngIf = "menuItem.queryVariable!=''" routerLink="{{menuItem.link}}" [queryParams]="buildQueryParams(menuItem)">{{menuItem.label}}</a>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </div>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>

            <!-- Setting icon start -->
            <ul class="setting-menu navbar-nav flex-row align-items-center">

                <li class="nav-item position-relative cursor-pointer" *ngIf="showBadge">
                    <a class="nav-link cursor-pointer d-inline p-0" routerLink="/{{christmasUrl}}">
                        <img src="{{badgeChristmas}}" alt="" title="" width="34" height="31">
                    </a>
                    <span class="menu-title d-lg-none ps-1">{{'web.nav_christmas_txt' | translate}}</span>
                    <span class="top-tooltip">{{'web.nav_christmas_tooltip' | translate}}</span>
                </li>
                
                <li class="nav-item position-relative cursor-pointer">
                    <a class="nav-link cursor-pointer d-inline p-0" routerLink="/{{myphotosUrl}}">
                        <img src="{{myPhotosMenu}}" alt="" title="" width="34" height="31">
                    </a>
                    <span class="menu-title d-lg-none ps-1">{{'web.nav_my_photos_txt' | translate}}</span>
                    <!-- <span class="top-tooltip">{{'web.nav_my_photos_tooltip' | translate}}</span> -->
                </li>

                <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="userInfo && !isUserTypeGuest">
                    <span class="nav-link cursor-pointer d-inline p-0" id="dropdownMenuUserInfoLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <img (mouseover)='over()' (mouseout)='out()' src="{{loginMenuIcon}}" [ngClass]="{'ProfileLoggedIn': userInfo && !isUserTypeGuest}" alt="" title="" title="" width="34" height="34">
                        <span class="menu-title d-lg-none ps-1">{{'web.nav_user_after_login_txt' | translate}}</span>
                    </span>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuUserInfoLink">
                        <div class="dropdown-item user-name d-block overflow-hidden">{{userName}}</div>
                        <a class="dropdown-item" routerLinkActive="active" *ngIf="userInfo && !isUserTypeGuest" routerLink="/{{orderUrl}}">
                            <img src="{{shoppingBag}}" alt="" title="" title="">{{'web.home_myorders' | translate}}
                        </a>

                        <a class="dropdown-item" routerLinkActive="active" *ngIf="userInfo && !isUserTypeGuest" routerLink="/{{myprojectUrl}}">
                            <img src="{{editProjectIcon}}" alt="" title="" title="">{{'web.home_my_project' | translate}}
                        </a>

                        <a class="dropdown-item" routerLinkActive="active" *ngIf="userInfo && !isUserTypeGuest" routerLink="/{{mysharedProjectUrl}}">
                            <img src="{{editProjectIcon}}" alt="" title="" title="">{{'web.home_my_shared_project' | translate}}
                        </a>

                    <a class="dropdown-item" routerLinkActive="active" routerLink="/{{myAccountUrl}}">
                        <img src="{{userProfileIcon}}" alt="" title="" title="">{{'web.home_myaccount' | translate}}
                    </a>

                    <a class="dropdown-item" routerLinkActive="active" routerLink="/{{myphotosUrl}}">
                            <img src="{{photosMenuIcon}}" alt="" title="" title="">{{'web.home_myphotos' | translate}}
                    </a>

                        <span class="dropdown-item"
                            *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='0' || userInfo.Newsletter=='3')"
                            (click)="subscribeNewsletter(userInfo,myAccountUrl)"><img src="{{checkmarkCross}}" alt=""
                                title="">{{'web.account_dropdown_nl_not_subscribed' | translate}}</span>

                        <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='1')"><img
                                src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_subscribed' |
                            translate}}</span>

                            <a class="dropdown-item" routerLinkActive="active" routerLink="/{{myAccountUrl}}" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='2')" click><img
                                src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_awaiting_confirmation' |
                            translate}}</a>

                        <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" (click)="logout()"><img src="{{Userlogout}}" alt="" title="">{{'web.home_logout' | translate}}</span>

                        <span class="dropdown-item" *ngIf="userInfo && isUserTypeGuest" (click)="logout()">
                        {{'web.user_dropdown_exit_guest_session' | translate}}</span>
                    </div>
                </li>
                <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="!userInfo || isUserTypeGuest">
                    <span class="nav-link cursor-pointer position-relative d-inline p-0" id="dropdownMenuNotUserInfoLink" data-bs-toggle="dropdown">
                        <img (mouseover)='over()' (mouseout)='out()' src="{{headerProfile}}" alt="" title="" width="34" height="34" class="beforeLogin">
                        <span class="menu-title d-lg-none ps-1">{{'web.nav_user_before_login_txt' | translate}}</span>
                    </span>

                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuNotUserInfoLink">
                        <span class="dropdown-item" (click)="showSignUpModal('')">
                            <img src="{{userLogin}}" alt="" title="">{{'web.home_login' | translate}}
                        </span>
                        <span class="dropdown-item" (click)="showSignUpModal('')">
                            <img src="{{userRegister}}" alt="" title="">{{'web.home_register' | translate}}
                        </span>
                    </div>
                </li>

                <li class="nav-item cart">
                    <a class="nav-link position-relative d-inline p-0" routerLink="/{{cartUrl}}">
                        <img src="{{cartBag}}" alt="" title="" width="34" height="31">
                        <span class="cart-tag" *ngIf="arrCartLength != 0">{{arrCartLength}}</span>
                    </a>
                    <span class="menu-title d-lg-none ps-1">{{'web.nav_my_cart_txt' | translate}}</span>
                    <!-- <span class="top-tooltip">{{'web.nav_my_cart_tooltip' | translate}}</span> -->
                </li>

                <li class="nav-item dropdown lang d-none" *ngIf="arrLanguage.length>1">
                    <a class="nav-link d-inline p-0" id="dropdownMenuLanguageLink" data-bs-toggle="dropdown">
                        <img src="{{langIcon}}" alt="" title="" width="34" height="32">
                        <!-- <span class="langLabel d-sm-inline-block d-none"> {{languageLabel}}</span> -->
                    </a>
                    <span class="menu-title d-none d-lg-inline ps-1">{{'web.nav_lang_txt' | translate}}</span>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLanguageLink">
                        <ul class="list-unstyled">
                            <li *ngFor="let language of arrLanguage" (click)="changeLanguage(language.lang)">
                                <span class="dropdown-item">
                                    <span class="small">{{language.lang}}</span><span class="large">{{language.langValue}}</span></span>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <!-- Setting icon end -->    
        </nav>

        <div class="text-center mb-2 centerLogo">
            <a class="navbar-brand d-lg-none mt-2 d-inline-block" routerLink="/">
                <img src="{{headerLogo}}" alt="" width="80" height="41" title="{{headerData.fields.logo.fields.title}}" alt="{{headerData.fields.logo.fields.description}}">
            </a>
        </div>
    </div>
</div>