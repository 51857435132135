<div id="sessionpopup" data-bs-keyboard="true">
    <div class="p-3" *ngIf="type == 'SessionOut'">
        <div class="modal-header px-0 pt-0 pb-2 bg-transparent">
            <div class="modal-title" *ngIf="type == 'SessionOut'">{{'web.session_timeout_popup_title' | translate}}</div>
            <a type="button" class="ms-auto" data-bs-dismiss="modal" aria-label="Close" (click)="submit()">
                <img src="https://cdn.sw.fit/FS-AT/icons/checkmark-cross-32.svg" alt="" />
            </a>
        </div>
        <div class="modal-body text-start px-0 pt-3 pb-0">
            <div class="main-add-more-page">
                <form>
                    <div class="form-check ps-0" [hidden]="timeOut">{{'web.session_timeout_confirmation_text' | translate}} </div>
                    <!-- <div class="form-check" [hidden]="!timeOut">{{'web.session_timeout_password_text' | translate}}</div> -->
                    <div class="d-flex align-items-center mt-3 justify-content-center" [hidden]="timeOut">
                        <button type="button" class="btn btn-light me-2"
                            (click)="submit()">{{'web.session_timeout_confirmation_btn_cancel' | translate}}</button>
                        <button type="submit" class="btn btn-primary"
                            (click)="openPassWin()">{{'web.session_timeout_confirmation_btn_ok' | translate}}</button>
                    </div>
                    <!-- <div class="text-center mt-3" [hidden]="!timeOut">
                        <button type="button" class="btn cancel_btn"
                            (click)="submit()">{{'web.session_timeout_password_btn_no' | translate}}</button>
                        <button type="submit" class="btn save_btn"
                            (click)="openPassWin()">{{'web.session_timeout_password_btn_yes' | translate}}</button>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
    
    <div class="p-3" *ngIf="type == 'nullOuth'">
        <div class="modal-header px-0 pt-0 pb-2 bg-transparent">
            <h4 class="modal-title" *ngIf="type == 'nullOuth'">{{'web.session_expired_popup_title' | translate}}</h4>
            <a type="button" class="ms-auto" data-bs-dismiss="modal" aria-label="Close" (click)="submit()">
                <img src="https://cdn.sw.fit/FS-AT/icons/checkmark-cross-32.svg" alt="" />
            </a>
        </div>
        <div class="modal-body text-start px-0 pt-3 pb-0">
            <div class="main-add-more-page">
                <form>
                    <div class="form-check ps-0">{{'web.session_expired_popup_text' | translate}}</div>
    
                    <div class="d-flex justify-content-center mt-3">
                        <button type="submit" class="btn btn-primary"
                            (click)="submit()">{{'web.session_expired_popup_btn_ok' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    <div class="p-3" *ngIf="type == 'enterPass'">
        <div class="modal-header  px-0 pt-0 pb-2 bg-transparent">
            <h4 class="modal-title">{{'web.session_timeout_password_popup_title' | translate}}</h4>
            <a type="button" class="ms-auto" data-bs-dismiss="modal" aria-label="Close" (click)="submit()">
                <img src="https://cdn.sw.fit/FS-AT/icons/checkmark-cross-32.svg" alt="" />
            </a>
        </div>
        <div class="modal-body text-start px-0 pt-3 pb-0">
            <div class="main-add-more-page">
                <form>
                    <div class="main-add-more-page position-relative">
                        <input id="passInput" maxlength="25" class="form-control" [type]="passwordShow ? 'text' : 'password'" name="password"
                            placeholder="{{'web.session_timeout_password_field_label' | translate}}" [(ngModel)]="userPass" (keyup)="passFormat(1)"/>
                            <span class="cursor-pointer position-absolute passwordToggle" (click)="passwordToggle()" *ngIf="showIcon">
                                <img src="{{passwordShow ? 'https://cdn.sw.fit/FS-AT/icons/preview-crossed-24.svg' : 'https://cdn.sw.fit/FS-AT/icons/preview-outline-24.svg'}}" alt="" title="">
                            </span>
                    </div>
                    <div class="text-danger">{{errMsg}}</div>
                    <div class="d-flex align-items-center mt-3 justify-content-center">
                        <button type="button" class="btn btn-light me-2"
                            (click)="submit()">{{'web.session_timeout_password_btn_cancel' | translate}}</button>
                        <button type="submit" class="btn btn-primary"
                            (click)="submitPass()">{{'web.session_timeout_password_btn_confirm' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>



