<footer class="FSC-footer" *ngIf="footerContent!=''">
    <div class="container">
        <div class="item-wrapper d-flex flex-wrap">
            <div class="item-{{i+1}}" *ngFor="let item of footerContent;let i=index;">
                <a class="footer-title mb-2 d-sm-none" data-bs-toggle="collapse" href="#collapse{{i}}" role="button" aria-expanded="false" aria-controls="collapse{{i}}" *ngIf="!item.hideTitle">{{item.title}}</a>
                
                <span class="footer-title mb-2 d-none d-sm-block" *ngIf="!item.hideTitle">{{item.title}}</span>
                
                <div [ngClass]="{'collapse': i < 4}" id="collapse{{i}}">
                    <ng-container *ngIf="item.imagesArray && !item.hideTitle">
                        <a href="" title="{{images.title}}" class="footer-payment d-inline-block" *ngFor="let images of item.imagesArray">
                            <img src="{{images.icon.fields.file.url}}" alt="" *ngIf="images.icon">
                        </a>
                    </ng-container>

                    <ng-container *ngIf="item.textArray && !item.hideTitle">
                        <a href="{{text.link}}" class="footer-link" *ngFor="let text of item.textArray">{{text.label}}</a>
                    </ng-container>

                    <ng-container *ngIf="item.hideTitle &&item.key =='colLogoAddress'">
                        <img src="{{item.title.fields.coLogo.fields.file.url}}" alt="" class="mb-3" *ngIf="item.title && item.title.fields && item.title.fields.coLogo" />
                        <div class="details" [innerHTML]="item.data"></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</footer>