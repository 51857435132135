<div id="cd-header" #headerDiv>
    <div [hidden]="!showHeadersection">
        <ng-container #headerWidget></ng-container>
        <div class="header-container position-relative">

             <!-------------------- Top Start setting menu --------------------------->
             <div class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <a class="w-auto me-4 landscape-logo d-none" routerLink="/">
                    <img src="{{headerLogo}}" width="60" height="41" title="{{headerData.fields.logo.fields.title}}"
                        alt="{{headerData.fields.logo.fields.description}}">
                </a>
                <div class="d-flex">
                    <ul class="setting-menu navbar-nav flex-row align-items-center" *ngFor="let item of subMenuData.fields.subMenu">
                        <li class="nav-item d-none d-lg-inline" *ngIf="item && item.fields && item.fields.link && item.fields.label && item.fields.icon  && item.fields.icon.fields && item.fields.icon.fields.title && item.fields.icon.fields.file && item.fields.icon.fields.file.url">
                            <a class="nav-link d-flex align-items-center p-0" *ngIf="item.fields.link && item.fields.link.includes('https://')" [href]="item.fields.link" target="_blank">
                                <img src="{{item.fields.icon.fields.file.url}}" alt="" title="{{item.fields.icon.fields.title}}">
                                <span class="menu-title ps-1">{{item.fields.label}}</span>
                            </a>
                            <a class="nav-link d-flex align-items-center p-0" *ngIf="item.fields.link && !item.fields.link.includes('https://')" routerLink="/{{item.fields.link}}">
                                <img src="{{item.fields.icon.fields.file.url}}" alt="" title="{{item.fields.icon.fields.title}}">
                                <span class="menu-title ps-1">{{item.fields.label}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- <ul class="setting-menu navbar-nav flex-row align-items-center">
                    <li class="nav-item d-none d-lg-inline">
                        <a class="nav-link d-flex align-items-center p-0" routerLink="/{{faqUrl}}">
                            <img src="{{imageIconPath}}help-32.svg" alt="" title="">
                            <span class="menu-title ps-1">{{'web.nav_help_faq_txt' | translate}}</span>
                            <span class="top-tooltip">{{'web.nav_help_faq_tooltip' | translate}}</span>
                        </a>
                    </li> 
                    <li class="nav-item d-none d-lg-inline">
                        <a class="nav-link d-flex align-items-center p-0" routerLink="/{{contactUrl}}">
                            <img src="{{imageIconPath}}contact-24.svg" alt="" title="">
                            <span class="menu-title ps-1">{{'web.nav_contact_txt' | translate}}</span>
                            <span class="top-tooltip">{{'web.nav_contact_tooltip' | translate}}</span>
                        </a>
                    </li> 
                    <li class="nav-item d-none d-lg-inline">
                        <a class="nav-link d-flex align-items-center p-0" routerLink="/{{designerUrl}}">
                            <img src="{{imageIconPath}}designer-24.svg" alt="" title="">
                            <span class="menu-title ps-1">{{'web.nav_designer_txt' | translate}}</span>
                            <span class="top-tooltip">{{'web.nav_designer_tooltip' | translate}}</span>
                        </a>
                    </li>
                </ul> -->

                <!-- Right side menu start-->
                <ul class="ms-lg-auto setting-menu navbar-nav flex-row align-items-center position-relative">
                    
                    <li class="nav-item" *ngIf="showBadge">
                        <a class="nav-link d-flex align-items-center p-0 123" routerLink="/{{christmasUrl}}">
                            <img src="{{badgeChristmas}}" alt="" title="">
                            <span class="menu-title ps-1">{{'web.nav_christmas_txt' | translate}}</span>
                            <span class="top-tooltip">{{'web.nav_christmas_tooltip' | translate}}</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link d-flex align-items-center p-0" routerLink="/{{myphotosUrl}}">
                            <img src="{{myPhotosMenu}}" alt="" title="">
                            <span class="menu-title ps-1">{{'web.nav_my_photos_txt' | translate}}</span>
                            <span class="top-tooltip">{{'web.nav_my_photos_tooltip' | translate}}</span>
                        </a>
                    </li>
                    <li class="nav-item dropdown position-static user-dropdown cursor-pointer" *ngIf="userInfo && !isUserTypeGuest">
                        <span class="nav-link d-flex align-items-center p-0" id="dropdownMenuUserInfoLink" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <!-- (mouseover)='over()' (mouseout)='out()' -->
                            <img src="{{loginMenuIcon}}"
                                [ngClass]="{'ProfileLoggedIn': userInfo && !isUserTypeGuest}" alt="" title="" title="">
                            <span class="menu-title ps-1">{{'web.nav_user_after_login_txt' | translate}}</span>
                        </span>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuUserInfoLink">
                            <div class="dropdown-item user-name d-block overflow-hidden">{{userName}}</div>
                            <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLinkActive="active" routerLink="/{{orderUrl}}"><img
                                    src="{{shoppingBag}}" alt="" title="" title="">{{'web.home_myorders' | translate}}</a>

                            <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLinkActive="active" routerLink="/{{myprojectUrl}}"><img
                                    src="{{editProjectIcon}}" alt="" title="" title="">{{'web.home_my_project' | translate}}</a>

                            <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLinkActive="active"
                                routerLink="/{{mysharedProjectUrl}}"><img src="{{editProjectIcon}}" alt="" title=""
                                    title="">{{'web.home_my_shared_project' | translate}}</a>

                            <a class="dropdown-item" routerLink="/{{myAccountUrl}}" routerLinkActive="active"><img src="{{userProfileIcon}}" alt=""
                                    title="" title="">{{'web.home_myaccount' | translate}}
                            </a>

                            <a class="dropdown-item" routerLink="/{{myphotosUrl}}" routerLinkActive="active"><img src="{{photosMenuIcon}}" alt=""
                                    title="" title="">{{'web.home_myphotos' | translate}}
                            </a>

                            <span class="dropdown-item"
                                *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='0' || userInfo.Newsletter=='3')"
                                (click)="subscribeNewsletter(userInfo,myAccountUrl)"><img src="{{checkmarkCross}}" alt=""
                                    title="">{{'web.account_dropdown_nl_not_subscribed' | translate}}</span>

                            <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='1')"><img
                                    src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_subscribed' |
                                translate}}</span>

                            <a class="dropdown-item" routerLink="/{{myAccountUrl}}" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='2')" click>
                                <img src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_awaiting_confirmation' |
                                translate}}</a>


                            <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" (click)="logout()"><img
                                    src="{{Userlogout}}" alt="" title="">{{'web.home_logout' | translate}}</span>

                            <span class="dropdown-item" *ngIf="userInfo && isUserTypeGuest" (click)="logout()">
                                {{'web.user_dropdown_exit_guest_session' | translate}}</span>
                        </div>
                    </li>
                    <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="!userInfo || isUserTypeGuest">
                        <span class="nav-link cursor-pointer position-relative d-flex align-items-center p-0" id="dropdownMenuNotUserInfoLink"
                            data-bs-toggle="dropdown">
                            <img src="{{headerProfile}}" alt="" title="" class="beforeLogin">
                            <span class="menu-title ps-1">{{'web.nav_user_before_login_txt' | translate}}</span>
                        </span>

                        <div class="dropdown-menu loginBefore dropdown-menu-end" aria-labelledby="dropdownMenuNotUserInfoLink">
                            <span class="dropdown-item" (click)="showSignUpModal('')">
                                <img src="{{userLogin}}" alt="" title="">{{'web.home_login' | translate}}
                            </span>
                            <span class="dropdown-item" (click)="showSignUpModal('')">
                                <img src="{{userRegister}}" alt="" title="">{{'web.home_register' | translate}}
                            </span>
                        </div>
                    </li>

                    <li class="nav-item cart">
                        <a class="nav-link position-relative d-flex align-items-center p-0"  routerLink="/{{cartUrl}}">
                            <img src="{{cartBag}}" alt="" title="">
                            <span class="cart-tag" *ngIf="arrCartLength != 0">{{arrCartLength}}</span>
                            <span class="menu-title ps-1">{{'web.nav_my_cart_txt' | translate}}</span>
                            <span class="top-tooltip">{{'web.nav_my_cart_tooltip' | translate}}</span>
                        </a>
                    </li>
                    <li class="nav-item dropdown lang" *ngIf="arrLanguage.length>1">
                        <a class="nav-link d-inline p-0 cursor-pointer" id="dropdownMenuLanguageLink" data-bs-toggle="dropdown">
                            <img src="{{langIcon}}" alt="" title="">
                            <span class="langLabel d-sm-inline-block d-none"> {{languageLabel}}</span>
                        </a>
                        <span class="menu-title ps-1">{{'web.nav_lang_txt' | translate}}</span>
                        <div class="dropdown-menu dropdown-menu-start langDropdown" aria-labelledby="dropdownMenuLanguageLink">
                            <ul class="list-unstyled">
                                <li *ngFor="let language of arrLanguage" (click)="changeLanguage(language.lang)">
                                    <a class="dropdown-item cursor-pointer">
                                        <span class="small pe-1">{{language.lang}}</span>
                                        <span class="large">{{language.langValue}}</span></a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <!------------------- Top End setting menu -------------------------------->
        
            <div class="centerLogo text-center">
                <a routerLink="/">
                    <img src="{{headerLogo}}" width="150" height="81" title="{{headerData.fields.logo.fields.title}}"
                        alt="{{headerData.fields.logo.fields.description}}">
                </a>
            </div>

            <nav class="navbar navbar-expand-lg px-0">
                <div class="category-container d-lg-flex align-items-center justify-content-between m-auto">
                    <button class="border-0 bg-transparent navbar-toggler px-1" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation" (click)="mobileHamburger()">
                        <img src="{{hamburgerMenu}}" alt="" title="" width="24" height="24">
                    </button>

                    <a class="navbar-brand cursor-pointer py-0 me-2 w-auto" routerLink="/">
                        <img src="{{headerLogo}}" width="75" height="41" title="{{headerData.fields.logo.fields.title}}"
                            alt="{{headerData.fields.logo.fields.description}}">
                    </a>

                    <div class="collapse navbar-collapse justify-content-sm-center" id="navbarSupportedContent">
                        <ul class="navbar-nav" id="menuAccordion">
                            
                            <!------------------- Side panel logo start--------------->
                            <li class="bg-light p-2 d-flex align-items-center justify-content-between d-lg-none">
                                <a class="py-0 mx-auto"  routerLink="/">
                                    <img src="{{headerLogo}}" width="75" height="41" (click)="closeSidePanel()"
                                        title="{{headerData.fields.logo.fields.title}}"
                                        alt="{{headerData.fields.logo.fields.description}}">
                                </a>
                                <img src="{{imageIconPath}}checkmark-cross-32.svg" (click)="closeSidePanel()"
                                    alt="close">
                            </li>
                            <!------------------- Side panel logo end--------------->

                            <!------------------- Category start--------------->
                            <li class="nav-item position-relative d-lg-flex align-items-center" id="menuParent{{i}}"
                                [routerLinkActive]="['active']" *ngFor="let category of categoryData; let i = index"
                                id="menuParent{{i}}">

                                <span class="d-flex align-items-center justify-content-between">
                                    <a class="nav-link" [routerLink]="category.categoryUrl" (click)="closeSidePanel()"
                                        [ngStyle]="{'color': category.breadcrumb_text}"
                                        style="--mainMenuHoverColor: {{category.more_info}};white-space: nowrap;">{{category.labelText}}</a>
                                    <img src="{{imageIconPath}}arrow-right-16.svg" alt="" *ngIf="category.dropDownItems"
                                        class="d-lg-none d-inline-block me-3 arrowLabel" data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#menuItem'+ i" (click)="checkExpandCollapse(i)">
                                </span>

                                <span class="badge badge-danger cursor-pointer" (click)="gotoPrints($event)"
                                    *ngIf="category.preview=='PRINT_PREVIEW' && category.printCounts && category.printCounts>0">{{category.printCounts}}</span>
                                <span class="top-tooltip mt-2 cursor-pointer">{{'web.prints_photo_count_badge_tooltip' |
                                    translate}}</span>

                                <div class="submenu-container collapse" id="menuItem{{i}}" data-parent="#menuAccordion"
                                    *ngIf="category.dropDownItems">

                                    <div class="row m-0">
                                        <div class="col accordion" *ngFor="let item of category.dropDownItems let i = index"
                                            id="subMenuAccordion">
                                            <!-- <pre>{{item | json}}</pre> -->
                                            <span class="border-bottom d-flex align-items-center justify-content-between">
                                                <h4 class="title mb-0">
                                                    <span class="title d-lg-block d-inline-block">{{item.title}}</span>
                                                </h4>
                                                <img src="{{imageIconPath}}arrow-right-16.svg" alt=""
                                                    class="d-lg-none d-inline-block arrowLabel" data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#subMenuItem'+ i">
                                            </span>
                                            <ng-container *ngIf="item.data">
                                                <ul class="ps-0 collapse" id="subMenuItem{{i}}" data-parent="#subMenuAccordion">
                                                    <li *ngFor="let menuItem of item.data" (click)="closeSidePanel()">
                                                        <a *ngIf = "menuItem.queryVariable==''" routerLink="{{menuItem.link}}">{{menuItem.label}}</a>
                                                        <a *ngIf = "menuItem.queryVariable!=''" routerLink="{{menuItem.link}}" [queryParams]="buildQueryParams(menuItem)">{{menuItem.label}}</a>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <!------------------- Category end--------------->
                        </ul>
                    </div>
                    <!---------------- Fixed header setting icon start ------------------------>
                    <ul class="nav menuIcons">

                        <!-- <li class="nav-item">
                            <a class="nav-link mx-1 p-0" routerLink="/{{myphotosUrl}}">
                                <img src="{{badgeChristmas}}" alt="" title="" width="18" height="18">
                            </a>
                        </li> -->

                        <li class="nav-item">
                            <a class="nav-link mx-1 p-0" routerLink="/{{myphotosUrl}}">
                                <img src="{{myPhotosMenu}}" alt="" title="" width="18" height="18">
                            </a>
                        </li>

                        <li class="nav-item cart" >
                            <a class="nav-link position-relative mx-1 p-0"  routerLink="/{{cartUrl}}">
                                <img src="{{cartBag}}" alt="" title="" width="18" height="18">
                                <span class="cart-tag" *ngIf="arrCartLength != 0">{{arrCartLength}}</span>
                            </a>
                        </li>

                        <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="userInfo && !isUserTypeGuest">
                            <span class="nav-link mx-1 p-0" id="dropdownMenuUserInfoLink" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <img src="{{loginMenuIcon}}"
                                    [ngClass]="{'ProfileLoggedIn': userInfo && !isUserTypeGuest}" alt="" title="" width="18" height="18">
                            </span>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuUserInfoLink">
                                <div class="dropdown-item user-name d-block overflow-hidden">{{userName}}</div>
                                <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLinkActive="active" routerLink="/{{orderUrl}}">
                                    <img src="{{shoppingBag}}" alt="" title="" title="">{{'web.home_myorders' | translate}}</a>
            
                                <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLinkActive="active" routerLink="/{{myprojectUrl}}">
                                    <img src="{{editProjectIcon}}" alt="" title="" title="">{{'web.home_my_project' | translate}}</a>
            
                                <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLinkActive="active"
                                    routerLink="/{{mysharedProjectUrl}}"><img src="{{editProjectIcon}}" alt="" title=""
                                        title="">{{'web.home_my_shared_project' | translate}}</a>
            
                                <a class="dropdown-item" routerLink="/{{myAccountUrl}}" routerLinkActive="active"><img src="{{userProfileIcon}}" alt=""
                                        title="" title="">{{'web.home_myaccount' | translate}}
                                </a>
            
                                <a class="dropdown-item" routerLink="/{{myphotosUrl}}" routerLinkActive="active"><img src="{{photosMenuIcon}}" alt=""
                                        title="" title="">{{'web.home_myphotos' | translate}}
                                </a>
            
                                <span class="dropdown-item"
                                    *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='0' || userInfo.Newsletter=='3')"
                                    (click)="subscribeNewsletter(userInfo,myAccountUrl)"><img src="{{checkmarkCross}}" alt=""
                                        title="">{{'web.account_dropdown_nl_not_subscribed' | translate}}</span>
            
                                <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='1')"><img
                                        src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_subscribed' |
                                    translate}}</span>
            
                                <a class="dropdown-item" routerLink="/{{myAccountUrl}}" routerLinkActive="active" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='2')" click><img
                                        src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_awaiting_confirmation' |
                                    translate}}</a>
            
            
                                <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" (click)="logout()"><img
                                        src="{{Userlogout}}" alt="" title="">{{'web.home_logout' | translate}}</span>
            
                                <span class="dropdown-item" *ngIf="userInfo && isUserTypeGuest" (click)="logout()">
                                    {{'web.user_dropdown_exit_guest_session' | translate}}</span>
                            </div>
                        </li>

                        <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="!userInfo || isUserTypeGuest">
                            <span class="nav-link cursor-pointer position-relative mx-2 p-0" id="dropdownMenuNotUserInfoLink"
                                data-bs-toggle="dropdown">
                                <img src="{{headerProfile}}" alt="" title="" class="beforeLogin" width="18" height="18">
                            </span>
            
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuNotUserInfoLink">
                                <span class="dropdown-item" (click)="showSignUpModal('')">
                                    <img src="{{userLogin}}" alt="" title="">{{'web.home_login' | translate}}
                                </span>
                                <span class="dropdown-item" (click)="showSignUpModal('')">
                                    <img src="{{userRegister}}" alt="" title="">{{'web.home_register' | translate}}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <!---------------- Fixed header setting icon end ------------------------>
                </div>
            </nav>
        </div>
    </div>
</div>