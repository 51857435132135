<div id="cd-header" #headerDiv class="sticky-top top-0 bg-white">
  <ng-container #headerWidget></ng-container>

  <div *ngIf="showHeadersection">
    <!----------------------------------- Start old-design ---------------------------------------->

   <nav class="navbar navbar-expand-lg py-0 px-3">
     <div class="d-flex align-items-center flex-lg-row-reverse dropright">
                <div class="dropdown m-auto">
    
                    <button class="border-0 bg-transparent ps-0 pe-3 d-lg-none" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="{{hamburgerMenu}}" alt="" title="" width="24" height="24">
                    </button>
                    <div class="dropdown-menu dropdown-menu-end navbar-collapse" aria-labelledby="dropdownMenuButton"
                        id="menu2">
                        <ul class="navbar-nav">
                            <li class="nav-item d-flex align-items-center"
                                *ngFor="let category of categoryData; let i = index">
                                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="category.categoryUrl"
                                    [ngStyle]="{'color': category.breadcrumb_text}"
                                    style="--mainMenuHoverColor: {{category.more_info}}">{{category.labelText}}</a>
    
                                <div class="show-tooltip cursor-pointer position-relative ms-2 ms-lg-0"
                                    *ngIf="category.preview=='PRINT_PREVIEW' && category.printCounts && category.printCounts>0">
                                    <span class="badge badge-danger" (click)="gotoPrints($event)"
                                        *ngIf="category.preview=='PRINT_PREVIEW' && category.printCounts && category.printCounts>0">{{category.printCounts}}</span>
                                    <span class="top-tooltip mt-2">{{'web.prints_photo_count_badge_tooltip' | translate}}</span>
                                </div>
    
                                <div class="submenu-container collapse" id="menuItem{{i}}" data-parent="#menuAccordion"
                            *ngIf="category.dropDownItems">
    
                            <div class="row m-0">
                                <div class="col accordion" *ngFor="let item of category.dropDownItems let i = index"
                                    id="subMenuAccordion">
                                    <span class="border-bottom d-flex align-items-center justify-content-between">
                                        <h4 class="title mb-0">
                                            <span class="title d-lg-block d-inline-block">{{item.title}}</span>
                                        </h4>
                                        <img src="https://cdn.sw.fit/FTK-DE/icons/arrow-right-16.svg" alt=""
                                            class="d-lg-none d-inline-block arrowLabel" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#subMenuItem'+ i">
                                    </span>
                                    <ng-container *ngIf="item.data">
                                        <ul class="ps-0 collapse" id="subMenuItem{{i}}" data-parent="#subMenuAccordion">
                                            <li *ngFor="let menuItem of item.data">
                                                <a *ngIf = "menuItem.queryVariable==''" routerLink="{{menuItem.link}}">{{menuItem.label}}</a>
                                                <a *ngIf = "menuItem.queryVariable!=''" routerLink="{{menuItem.link}}" [queryParams]="buildQueryParams(menuItem)">{{menuItem.label}}</a>
                                            </li>
                                        </ul>
    
                                    </ng-container>
                                </div>
                            </div>
    
                        </div>
    
                            </li>
                        </ul>
                    </div>
                </div>
    
                <a class="navbar-brand cursor-pointer" routerLink="/">
                    <img src="{{headerLogo}}" width="245" height="43" alt=""
                        title="{{'web.website_logo_name_tooltip' | translate}}" title="">
                </a>
      </div> 

    <!-- Start setting menu view -->
     <ul class="navbar-nav setting-menu">
                <li class="nav-item position-relative cursor-pointer">
                    <a class="nav-link p-0 position-relative" routerLink="/{{myphotosUrl}}">
                        <img src="{{myPhotosMenu}}" alt="" title="" >
                        <span class="d-block menu-title">{{'web.nav_my_photos_txt' | translate}}</span>
                    </a>
                    <span class="top-tooltip">{{'web.nav_my_photos_tooltip' | translate}}</span>
                </li>
                <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="userInfo && !isUserTypeGuest">
                    <span class="nav-link cursor-pointer p-0" id="dropdownMenuLink" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <img src="{{loginMenuIcon}}"
                            [ngClass]="{'ProfileLoggedIn': userInfo && !isUserTypeGuest}" alt="" title="" title="">                    
                    </span>
                    <span class="d-block menu-title">{{'web.nav_user_after_login_txt' | translate}}</span>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                        <div class="dropdown-item user-name d-block overflow-hidden">{{userName}}</div>
                        <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLink="/{{orderUrl}}"><img
                                src="{{shoppingBag}}" alt="" title="" title="">{{'web.home_myorders' | translate}}</a>
                        <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" routerLink ="/{{myprojectUrl}}"><img
                                src="{{editProjectIcon}}" alt="" title="" title="">{{'web.home_my_project' | translate}}</a>
                        <a class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest"
                            routerLink="/{{mysharedProjectUrl}}"><img src="{{editProjectIcon}}" alt="" title=""
                                title="">{{'web.home_my_shared_project' | translate}}</a>                    
                        <a class="dropdown-item" routerLink="/{{myAccountUrl}}"><img src="{{userProfileIcon}}" alt=""
                                title="" title="">{{'web.home_myaccount' | translate}}
                        </a>
    
                        <a class="dropdown-item" routerLink="/{{myphotosUrl}}"><img src="{{photosMenuIcon}}" alt=""
                                title="" title="">{{'web.home_myphotos' | translate}}
                        </a>
              
                        <span class="dropdown-item"
                        *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='0' || userInfo.Newsletter=='3')"
                        (click)="subscribeNewsletter(userInfo,myAccountUrl)"><img src="{{checkmarkCross}}" alt=""
                            title="">{{'web.account_dropdown_nl_not_subscribed' | translate}}</span>
    
                        <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='1')"><img
                                src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_subscribed' |
                            translate}}</span>
    
                            <a class="dropdown-item" routerLink="/{{myAccountUrl}}" *ngIf="userInfo && !isUserTypeGuest && (userInfo.Newsletter=='2')" click><img
                                src="{{newsletterIcon}}" alt="" title="">{{'web.account_dropdown_nl_awaiting_confirmation' |
                            translate}}</a>
    
                       
                        <span class="dropdown-item" *ngIf="userInfo && !isUserTypeGuest" (click)="logout()"><img
                                src="{{Userlogout}}" alt="" title="">{{'web.home_logout' | translate}}</span>
                        <span class="dropdown-item" *ngIf="userInfo && isUserTypeGuest" (click)="logout()">
                            {{'web.user_dropdown_exit_guest_session' | translate}}</span>
                    </div>
                </li>
                <li class="nav-item dropdown user-dropdown cursor-pointer" *ngIf="!userInfo || isUserTypeGuest">
                    <span class="nav-link cursor-pointer p-0 position-relative" id="dropdownMenuLink" data-bs-toggle="dropdown">
                        <img src="{{headerProfile}}" alt="" title="" class="beforeLogin">
                    </span>
                    <span class="d-block menu-title">{{'web.nav_user_before_login_txt' | translate}}</span>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                        <span class="dropdown-item" (click)="showSignUpModal('')">
                            <img src="{{userLogin}}" alt="" title="">{{'web.home_login' | translate}}
                        </span>
                        <span class="dropdown-item" (click)="showSignUpModal('')">
                            <img src="{{userRegister}}" alt="" title="">{{'web.home_register' | translate}}
                        </span>
                    </div>
                </li>
    
                <li class="nav-item cart">
                    <a class="nav-link p-0 position-relative" routerLink="/{{cartUrl}}">
                        <img src="{{cartBag}}" alt="" title="" >
                        <span class="cart-tag" *ngIf="arrCartLength != 0">{{arrCartLength}}</span>
                    </a>
                    <span class="d-block menu-title">{{'web.nav_my_cart_txt' | translate}}</span>
                    <span class="top-tooltip">{{'web.nav_my_cart_tooltip' | translate}}</span>
                </li>
                <li class="nav-item dropdown lang">
                    <span class="nav-link d-flex align-items-center ms-1 p-0" id="dropdownMenuLink"
                        data-bs-toggle="dropdown">
                        <img src="{{langIcon}}" alt="" title="">
                        <span class="langLabel d-sm-inline-block d-none fs-12"> {{languageLabel}}</span>
                    </span>
                    <span class="d-block menu-title">{{'web.nav_lang_txt' | translate}}</span>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                        <ul class="list-unstyled">
                            <li *ngFor="let language of arrLanguage" (click)="changeLanguage(language.lang)">
                                <span class="dropdown-item">
                                    <span class="small pe-2">{{language.lang}}</span>
                                    <span class="large">{{language.langValue}}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul> 
      <!-- End setting menu view -->
    </nav>

      <!-- Start mobile slider view -->
      <div class="mobile-view">
        <div class="navbar p-0">
          <div class="navbar-collapse">
            <ul class="navbar-nav">
              <li
                class="nav-item"
                *ngFor="let category of categoryData; let i = index"
              >
                <a
                  class="nav-link d-flex"
                  [routerLinkActive]="['active']"
                  [routerLink]="category.categoryUrl"
                  [ngStyle]="{ color: category.breadcrumb_text }"
                  >{{ category.labelText }}
                  <div
                    class="dropdown dropdown-badge"
                    *ngIf="
                      category.preview == 'PRINT_PREVIEW' &&
                      category.printCounts &&
                      category.printCounts > 0
                    "
                  >
                    <span
                      class="badge badge-danger ms-1"
                      (click)="gotoPrints($event)"
                      *ngIf="
                        category.preview == 'PRINT_PREVIEW' &&
                        category.printCounts &&
                        category.printCounts > 0
                      "
                      >{{ category.printCounts }}</span
                    >
                    <div class="dropdown-menu">
                      <span class="dropdown-item">{{
                        "web.prints_photo_count_badge_tooltip" | translate
                      }}</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> 
      <!-- End mobile slider view -->

    <!----------------------------------- End old-design ---------------------------------------->


  </div>
</div>