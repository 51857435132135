import { ErrorHandler } from '@angular/core';
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage1 = /Loading chunk [\d]+ failed/;       
    const chunkFailedMessage2=/Loading chunk/;
    const chunkFailedMessage3=/ChunkLoadError:/;
    const chunkFailedMessage4=/ChunkLoadError: Loading chunk/;
    if (
    (error.message.indexOf('loading chunk') >-1 )||
    (error.message.indexOf('Loading chunk') >-1 )||    
    chunkFailedMessage1.test(error.message)||
    chunkFailedMessage2.test(error.message)||
    chunkFailedMessage3.test(error.message)||
    chunkFailedMessage4.test(error.message)
    
    ) {
      
        window.location.reload();
     
    } else {
     
      throw error;
    }
  }
}