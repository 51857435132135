
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

import { environment } from 'src/environments/environment';
import * as global from 'src/app/globals';
import * as uuid from 'uuid';
import {Router } from '@angular/router';
import { ContentfulService } from '../services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
declare var $: any;

@Component({
    selector: 'app-design-selector',
    templateUrl: './design-selector.component.html',
    styleUrls: ['./design-selector.component.scss'],
    standalone: false
})
export class DesignSelectorComponent implements OnInit {

  productCode;
  groupCode;
  arrCurrentIdPagsList=[]; 
  arrAllIdPageList = [];
  arrIdPageVersion;
  currentIdPage
  currentViewDesign
  pageFooter: any = [];
  contentType: string = 'page';
  data: any;
 
  constructor(
    private resolver: ComponentFactoryResolver,
    private dataService:DataService,private activeRoute: ActivatedRoute,
    private route: Router,
    private contentfulService: ContentfulService
  ) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {

      this.productCode = params.get('productCode');
      this.groupCode = params.get('groupCode');
    });
    this.dataService.getIdPageList(undefined, this.productCode, 'Yes',this.groupCode).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      var data = JSON.parse(resSTR);
      
      if (data.success == true) {
        if(data.data)
        {
          this.arrIdPageVersion = [];
          for (var i = 0; i < data.data.length; i++) {
            if (data.data[i].parentPageId != '') {
              data.data[i].thumbSrc = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + 'idpages/' + data.data[i].parentPageId + '/thumb/200/' + data.data[i].parentPageId + "-content.jpg";
            }
            else {
              data.data[i].thumbSrc = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + 'idpages/' + data.data[i].pageId + '/thumb/200/' + data.data[i].pageId + "-content.jpg";
            }
            data.data[i].type = "idpage";
            if(data.data[i].pageVersion == "")
            {
              this.arrCurrentIdPagsList.push(data.data[i]);
              data.data[i].arrPageVersion = [];
            }
          }

          this.arrAllIdPageList = data.data;
          for(var i =0;i<this.arrCurrentIdPagsList.length;i++)
          {
            this.arrCurrentIdPagsList[i].arrPageVersion.push(this.arrCurrentIdPagsList[i]);
            this.arrCurrentIdPagsList[i].activeIndex = 0;
            for(var j =0; j < this.arrAllIdPageList.length;j++)
            {
              if(this.arrCurrentIdPagsList[i].ID == this.arrAllIdPageList[j].pageVersion)
              {
                this.arrCurrentIdPagsList[i].arrPageVersion.push(this.arrAllIdPageList[j]);
              }
            }
          }

  

          //this.filterPageVersion();
        }
       
      }
    });
    let arrUrl = this.route.url.split('/');
   
    let currentSlug = decodeURIComponent(arrUrl[1]);
    this.loadData(currentSlug);
  }
  loadData(pageSlug) {

    window.scrollTo(0, 0);
    this.contentfulService.getContentByFieldName(this.contentType, pageSlug).then(data => {
      // this.isLoading = false;
      this.prepareToLoad(data);
    })
  }
  prepareToLoad(data) {

    let headerObject = {
      headerVersion: false,
      headerData: false
    }
       

    if (data && data[0] &&  data[0].fields && data[0].fields['topSection']) {
      let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['topSection']))
      let headerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


      if (headerFound.length > 0) {
        let headerData = headerFound[0];
        if (headerData.fields.selectDesign) {
          let headerObject = {
            headerVersion: headerData.fields.selectDesign.split('-')[2],
            headerData
          }
          // 
          this.contentfulService.emitHeaderData(headerObject)

        }
      } else {
        this.contentfulService.emitHeaderData(headerObject)
      }


    } else {
      this.contentfulService.emitHeaderData(headerObject)
    }

   
    if (data && data[0] &&  data[0].fields && data[0].fields['pageFooter']) {

      this.pageFooter = data[0].fields['pageFooter']
      this.loadFooter()
    }
  }

  loadFooter() {

    let footerObject = {
      footerVersion: false,
      footerData: false
    }

    if (this.pageFooter != '') {

      let allComponentFilter = JSON.parse(JSON.stringify(this.pageFooter))
      let footerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


      if (footerFound.length > 0) {
        let footerData = footerFound[0];
        if (footerData.fields.selectDesign) {
          let footerObject = {
            footerVersion: footerData.fields.selectDesign.split('-')[2],
            footerData
          }
          //  
          this.contentfulService.emitFooterData(footerObject)

        }
      } else {
        this.contentfulService.emitFooterData(footerObject)
      }


    } else {
      this.contentfulService.emitFooterData(footerObject)
    }
  }

  filterPageVersion() {

    for (var i = 0; i < this.arrAllIdPageList.length; i++) {
      var idPage = this.arrAllIdPageList[i];
      if (idPage.pageVersion != "") {
        this.arrIdPageVersion.push(this.arrAllIdPageList[i]);
        this.arrAllIdPageList.splice(i, 1);
        i--;
      }
    }

    for(var i = 0; i < this.arrAllIdPageList.length;i++)
    {
      this.arrAllIdPageList[i].arrPageVersion = [];
      this.arrAllIdPageList[i].activeIndex = 0;
      var idPage = this.arrAllIdPageList[i];
      for (var j = 0; j < this.arrIdPageVersion.length; j++) {
        
        if (idPage.ID == this.arrIdPageVersion[j].pageVersion) {
          idPage.arrPageVersion.push(this.arrIdPageVersion[j]);
          this.arrAllIdPageList[i].arrPageVersion.unshift(this.arrAllIdPageList[i]);
        }
      }
      
    }  
    for(var i = 0; i < this.arrAllIdPageList.length;i++)
    {
      if(this.arrAllIdPageList[i].arrPageVersion.length == 0)
      {
        this.arrAllIdPageList[i].arrPageVersion.push(this.arrAllIdPageList[i])
      }
    }
  }

  changeVersion( design,index)
  {
    
    //
    
    design.activeIndex = index;
    
    

  }

  createProduct(design)
  {
    var version = design.arrPageVersion[design.activeIndex];
    var projectId = uuid.v4();
    let url = 'app/product/' + this.productCode + '/' + projectId+ '/' + version.ID;
    this.route.navigate([url]).then(result => {  }); 
  }

  showView(version)
  {
    this.currentIdPage = version;
    this.currentViewDesign =   this.currentIdPage.arrPageVersion[this.currentIdPage.activeIndex];
    var json = JSON.parse(this.currentViewDesign.json);
    this.currentViewDesign.arrImages = [];
    for (var i = 0; i < json.pages.length; i++) {
      var path = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + 'idpages/' + this.currentViewDesign.pageId + '/' + (i + 1) + ".jpg";
      this.currentViewDesign.arrImages.push(path);
    }
    $('#designSelectorView').modal('show');
  }
}
