<div class="design-wrapper">
    <div class="themes-title">{{'web.ie_design_selector_heading' | translate}}</div>
    
    
    
   <!-- <div id="collapseThemesFilter" class="themes-filter collapse">
        <button class="btn btn-outline-secondary active">Alle Vorlagen</button><button class="btn btn-outline-secondary">Einfache Farbthemen</button>
        <button class="btn btn-outline-secondary">Liebe, Hochzeit, Beziehung</button><button class="btn btn-outline-secondary">Sommer, Strand, Meer</button>
        <button class="btn btn-outline-secondary">Besondere Interessen</button><button class="btn btn-outline-secondary">Kinder, Feste, Baby</button>
        <button class="btn btn-outline-secondary">Reisen</button><button class="btn btn-outline-secondary">Weihnachten</button>
    </div>-->
    <div class="row">
        <div class="col col-sm-6 col-md-4" *ngFor="let design of arrCurrentIdPagsList;let i = index" >
            <div class="theme-item">
                <figure >
                    <img
                        
                        alt=""
                        crossorigin="anonymous"
                        class="idea-page position-absolute"
                        [src]="design.arrPageVersion[design.activeIndex].thumbSrc" 
                        title="{{design.title}}"
                    />
                    
                    <img onerror="this.src='assets/images/wrong.png'" class="idea-page-mask position-absolute" src="assets/images/wrong.png" />
                </figure>
                <div class="theme-content">
                    <div class="title">{{design.title}}</div>
                    <div *ngIf="design.arrPageVersion.length>1" >
                        
                        <span class="dot" [ngStyle]="{'background-color': '#'+version.color}"  (click)="changeVersion(design,i)" *ngFor="let version of design.arrPageVersion; let i = index"></span>
                      
                      </div>
                      
                    
                    <div class="sub-title" [hidden]="true">
                        Einfache, universelle Vorlage. Ein Farbthema für alle Gelegenheiten. Inspiriert durch Farb- und Formspiele die Freude zu deinen Fotos bringen. Über die Vorlageseiten hinaus kannst du unendlich viele Ornament und
                        Farbkombinationen in wenigen Klicks ganz einfach kreieren - ein Thema für jede Gelegenheit, jede Stimmung und jeden Geschmack.
                    </div>
                    
                    <div style="display:flex">
                        <a class="view-btn" type="button"  (click)="showView(design)" >{{'web.ie_design_selector_view' | translate}} ››</a>
                        <a class="view-btn" (click)="createProduct(design)" >{{'web.ie_design_selector_create' | translate}} ››</a>
                    </div>
                   
                </div>
            </div>
        </div>
       
    </div>
</div>




<!-- Modal -->
<div class="modal fade" id="designSelectorView" tabindex="-1" role="dialog" aria-labelledby="designSelectorView" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
            <div class="wrappper">
                <div class="carousel-wrap d-flex align-items-center justify-content-between">
                    <img src="https://cdn.sw.fit/FS-AT/icons/checkmark-cross-24.svg" alt="" type="button" class="position-absolute cursor-pointer" style="top: 8px; right: 14px;" data-bs-dismiss="modal" aria-label="Close" />
                    
                    <a href="#themeCarouselsModal" role="button" data-bs-slide="prev" class="carousel-control prev">
                        <img alt="" title="" src="https://cdn.sw.fit/FS-AT/web-assets/back-arrow.svg" /><span class="visually-hidden">Previous</span>
                    </a>
                    <div id="themeCarouselsModal" *ngIf="currentViewDesign" data-bs-ride="carousel" data-bs-interval="false" class="carousel slide">
                        <div class="carousel-inner">
                            <div class="carousel-item" *ngFor="let image of currentViewDesign.arrImages;let i = index" [ngClass]="{'active': i === 0}">
                                <img crossorigin="anonymous" alt="" title="" class="idea-page PB-LANDSCAPE" [src]="image" />
                                <img onerror="this.src='assets/images/wrong.png'" class="idea-page-mask position-absolute" src="assets/images/wrong.png" />
                            </div>
                            
                        </div>
                    </div>
                    <a href="#themeCarouselsModal" role="button" data-bs-slide="next" class="carousel-control next text-end">
                        <img alt="" title="" src="https://cdn.sw.fit/FS-AT/web-assets/forward-arrow.svg" /><span class="visually-hidden">Next</span>
                    </a>
                    
                </div>
                <div class="content custom-scroll">
                    <div class="head d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                        <div class="head-left">
                            <div class="d-flex align-items-start">
                                <div class="badge-wrapper position-relative">
                                    <img crossorigin="anonymous" alt="" class="badge-image pe-1" src="https://cdn.sw.fit/productbadge/FS-AT/badge-no-christmas.png" />
                                    <span class="position-absolute"></span>
                                </div>
                                
                                <div class="title heading3">
                                    <span class="theme-name" *ngIf="currentViewDesign">
                                        {{currentViewDesign.title}}
                                        
                                    </span>
                                    
                                    
                                    
                                </div>
                            </div>
                            <!--<div class="tag text16">
                                <span>Groß ca. A4 Panorama</span>
                                
                                <span>Hardcover</span>
                                
                            </div>-->
                        </div>
                        <!--<div class="head-right heading5 text-sm-right">
                            <div>
                                <span class="me-2">ab </span>
                                
                                <span>0,00 € </span>
                                
                            </div>
                            
                        </div>-->
                        
                    </div>
                    <!--<div class="pages heading6 mb-0">
                        <div class="f-12">Preis für 20 Seiten, %e je zusätzliche Seite</div>
                    </div>-->
                    <div class="prome-text heading6 text-danger mb-1"><span></span></div>
                    <!--<div class="feature d-flex flex-column-reverse flex-sm-column">
                        <div class="detail heading6 position-relative mb-2">
                            <div class="f-12">
                                Einfache, universelle Vorlage. Ein Farbthema für alle Gelegenheiten. Inspiriert durch Farb- und Formspiele die Freude zu deinen Fotos bringen. Über die Vorlageseiten hinaus kannst du unendlich viele Ornament und
                                Farbkombinationen in wenigen Klicks ganz einfach kreieren - ein Thema für jede Gelegenheit, jede Stimmung und jeden Geschmack.
                            </div>
                        </div>
                        <div class="filter d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                            <div class="d-flex flex-column flex-sm-row me-sm-5 align-items-sm-center flex-grow-1">
                                <div class="size d-flex justify-content-between flex-row flex-sm-column align-items-baseline">
                                    <label for="" class="text16 d-block fw-bold mb-0 f-12">Größe</label>
                                    <div class="dropdown select-box mb-0">
                                        <button type="button" id="collapseOnetag-PB-1" data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-link border0 pe-4">A4 Panorama</button>
                                        <div aria-labelledby="collapseOnetag-PB-1" class="dropdown-menu">
                                            <a class="dropdown-item">A4 Hoch</a><a class="dropdown-item">A4 Panorama</a><a class="dropdown-item">30x30</a>
                                            <a class="dropdown-item">45x30</a><a class="dropdown-item">21x21</a><a class="dropdown-item">15x15</a>
                                            <a class="dropdown-item">18x13</a><a class="dropdown-item">15x10</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="size d-flex justify-content-between flex-row flex-sm-column align-items-baseline">
                                    <label for="" class="text16 d-block fw-bold mb-0 f-12">Einband</label>
                                    <div class="dropdown select-box mb-0">
                                        <button type="button" id="collapseOnetag-PB-1" data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-link border0 pe-4">Hardcover</button>
                                        <div aria-labelledby="collapseOnetag-PB-1" class="dropdown-menu">
                                            <a class="dropdown-item">Hardcover</a><a class="dropdown-item">Softcover</a><a class="dropdown-item">Pro-Line</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="size d-flex justify-content-between flex-row flex-sm-column align-items-baseline">
                                    <label for="" class="text16 d-block fw-bold mb-0 f-12">Typ</label>
                                    <div class="dropdown select-box mb-0">
                                        <button type="button" id="collapseOnetag-PB-1" data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-link border0 pe-4">Digitaldruck</button>
                                        <div aria-labelledby="collapseOnetag-PB-1" class="dropdown-menu">
                                            <a class="dropdown-item">Digitaldruck</a><a class="dropdown-item">echtFoto</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="editor-buttons"><button class="btn btn-warning text-nowrap fw-bold py-sm-3">Jetzt gestalten ››</button></div>
                        </div>
                    </div>-->
                </div>
            </div>            
        </div>
      </div>
    </div>
  </div>