import { Component, Input, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
    selector: 'app-EL-Footer-1',
    templateUrl: './EL-Footer-1.component.html',
    styleUrls: ['./EL-Footer-1.component.scss'],
    host: { '[id]': 'componentId' },
    standalone: false
})
export class ELFooterV1Component implements OnInit {
  @Input()
  footerData: any;
  footerContent: any = [];
  newArr: any = [];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    //  

    setTimeout(() => {
      this.loadFooter()
    }, 1000);

  }


  loadFooter() {
    if (this.footerData) {
      for (var key in this.footerData) {
        if ( this.footerData[key] == '#' && key.includes('col')) {
          //colnames.push(key)
          number = key.match(/\d+/);
          if (number) {
            colno = parseInt(number[0]);
            this.footerData['col'+colno]=[]
          }

        }
      }
      //  
      this.footerContent = [];
      
      let titleIndex = 0;
      let indexvalue = 0;
     
      for (var key in this.footerData) {

          var number;
          var colno;

          if (typeof (this.footerData[key]) == 'string' && key != 'internalName' && key != 'selectDesign') {
            number = key.match(/\d+/);
            if (number) {
              colno = parseInt(number[0]);
            }

            this.footerContent.push({ 'key': key, 'title': this.footerData[key], data: [], colno })

          }
          if (typeof (this.footerData[key]) == 'object') {
            titleIndex++;
            if (this.footerContent.findIndex(subItems => subItems.key == 'col' + titleIndex + 'Title') < 0) {

              this.footerContent.push({ 'key': 'col' + titleIndex + 'Title', 'title': 'col' + titleIndex + 'Title', data: [], hideTitle: true, colno: titleIndex })

            }
            // 
          }
        

      }

    

      for (var key in this.footerData) {
      //  if (!this.footerData[key].includes('#')) {
          if (typeof (this.footerData[key]) == 'object') {
            indexvalue++;
            number = key.match(/\d+/);
            if (number) {
              colno = parseInt(number[0]);
             
            }
          
            let columnIndex = this.footerContent.findIndex(subItems => subItems.key == 'col' + colno + 'Title' )
          //  
            if (columnIndex > -1) {
              this.footerData[key].map(itemcolumns => {
                this.footerContent[columnIndex].data.push(itemcolumns.fields)
              })

            }
         // }
        }

      }



      this.footerContent.map(item => {
        item.link = this.sanitizer.bypassSecurityTrustUrl(item.link)

        if (item.hideTitle) {
          item.linkArray = item.data.filter(innerItem => !innerItem.link)
          item.textArray = item.data.filter(innerItem => innerItem.link);
          item.textArray.map(innerItems => {
            innerItems.link = this.sanitizer.bypassSecurityTrustUrl(innerItems.link)
            //console.info(innerItems.link)
          })
        }
        else {
          item.imagesArray = item.data.filter(innerItem => innerItem && innerItem.icon)
          item.textArray = item.data.filter(innerItem => innerItem && !innerItem.icon)
          item.textArray.map(innerItems => {
            innerItems.link = this.sanitizer.bypassSecurityTrustUrl(innerItems.link)
           // console.info(innerItems.link)
          })
        }
      })

      for (let i = 0; i < this.footerContent.length; i++) {
        const element = this.footerContent[i];
        if (element.data.length>0) {
          this.newArr.push(element.data);
        }
      }
      
    }

    // 
  }

}
