import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as global from 'src/app/globals';
// import * as Sentry from '@sentry/angular-ivy';
import * as Sentry from '@sentry/angular';

// import { configureScope } from '@sentry/angular-ivy';
declare const SENTRY_RELEASE: string;
var domain = location.origin;
var environmentFrom;
var userLoggedIn, userId, userType;
if (domain.includes('sw.fit')) {
  environmentFrom = 'STAGE'

} else if (domain.includes('master')) {
  environmentFrom = 'MASTER'
} else {
  environmentFrom = 'LIVE'
}

// 
var dsnKey;
if (domain.includes('sw.fit') && !domain.includes('localhost')) {
  // dsnKey = 'https://d542e50ac2e344c7b83412708f7504a3@o4505067535597568.ingest.sentry.io/4505109281308672';
  // callSentry(dsnKey)
} else if (!domain.includes('sw.fit') && !domain.includes('localhost')) {
  dsnKey = 'https://f7d86ca8dc4d47a8aa63609dc317708a@o4505067535597568.ingest.sentry.io/4505067545821184'
  console.info(window['isOffline']) ;
  
  if(!window['isOffline']){
    // callSentry(dsnKey)
  }
  
}


function callSentry(dsnKey) {
  if(window['isOffline']){
    return false;
  }
  if (localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '') {
    userLoggedIn = 'NO';
    userId = 'NA';
    userType = 'NA'
  } else {
    userLoggedIn = 'Yes';
    userId = JSON.parse(localStorage.getItem('userInfo')).userId;
    userType = JSON.parse(localStorage.getItem('userInfo')).userType;
  }

  Sentry.init({
    dsn: dsnKey,
    release: SENTRY_RELEASE, // this line is creating issue,
    beforeSend(event, hint: any) {
      /* tslint:disable:no-string-literal only-arrow-functions */
      // var hint.originalException = hint.originalException;
     


      const isNonErrorException =
        event.exception.values[0].value.startsWith('Non-Error exception captured') ||
        hint.originalException['message'].startsWith('Non-Error exception captured') ||

        event.exception.values[0].value.startsWith('Http failure response for') ||
        hint.originalException['message'].startsWith('Http failure response for') ||

        event.exception.values[0].value.startsWith('NotSupportedError: Failed to register a ServiceWorker for scope') ||
        hint.originalException['message'].startsWith('NotSupportedError: Failed to register a ServiceWorker for scope') ||

        event.exception.values[0].value.startsWith('InvalidStateError: Failed to register a ServiceWorker: The document is in an invalid state') ||
        hint.originalException['message'].startsWith('InvalidStateError: Failed to register a ServiceWorker: The document is in an invalid state') ||


        event.exception.values[0].value.startsWith('wrsParams.serviceWorkers') ||
        hint.originalException['message'].startsWith('wrsParams.serviceWorkers') ||

        event.exception.values[0].value.startsWith('Error: Rejected') ||
        hint.originalException['message'].startsWith('Error: Rejected') ||
        hint.originalException['message'].includes('Rejected') ||
        hint.originalException['message'].includes('Failed to register a ServiceWorker') ||
        hint.originalException['message'].includes("Cannot read properties of null (reading 'removeChild')") ||
        hint.originalException['message'].includes("contentWindow.postMessage") ||
        (hint.originalException && hint.originalException != null && hint.originalException['message'] && hint.originalException['message'] != null && hint.originalException != '' && hint.originalException['message'].match(/Error: Rejected/) != null) ||
        (hint.originalException && hint.originalException != null && hint.originalException['message'] && hint.originalException['message'] != null && hint.originalException != '' && hint.originalException['message'].match(/null is not an object \(evaluating 'mt\.drawImage'\)/) != null) ||
        (hint.originalException && hint.originalException != null && hint.originalException['message'] && hint.originalException['message'] != null && hint.originalException != '' && hint.originalException['message'].match(/wrsParams\.serviceWorkers/) != null) ||

        (hint.originalException && hint.originalException != null && hint.originalException['message'] && hint.originalException['message'] != null && hint.originalException != '' && hint.originalException['message'].match(/null is not an object \(evaluating 'c\.parentNode\.removeChild'\)/i) != null) ||

        (hint.originalException && hint.originalException != null && hint.originalException['message'] && hint.originalException['message'] != null && hint.originalException != '' && hint.originalException['message'].match(/null is not an object \(evaluating '\(0,u\.default\)\(f,G\)\.contentWindow\.postMessage'\)/i) != null
        ) ||

        (hint.originalException && hint.originalException != null && hint.originalException['message'] && hint.originalException['message'] != null && hint.originalException != '' && hint.originalException['message'].match(/Cannot read properties of null \(reading 'postMessage'\)/i) != null);

      /* tslint:enable:no-string-literal only-arrow-functions */

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null;
      }
      return event;
    },
    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.routingInstrumentation,
      // }),
      // new Sentry.Replay(),


    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Sentry.configureScope((scope) => {
    Sentry.setUser({
      'IS_USER_LOGGED_IN': userLoggedIn,
      'USER_ID': userId,
      'USER_TYPE': userType,
      'environmentType': window['environmentType'],
      'defaultLang': window['defaultLang'],
      'countrySlugRequired': window['countrySlugRequired'],

    });


  // });
}



if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  let moduleName:any;
  // to load Fotosystem modules
  // if (environment.compileType == 'FSC') {
  //   moduleName = FscAppModule
  // }
  // else{
    // moduleName = AppModule
  // }

  // console.log('moduleName',moduleName);
  
  platformBrowserDynamic().bootstrapModule(AppModule).then((res) => {
    
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  }).catch(err => console.log(err));
};

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

