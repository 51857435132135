import { Component, OnInit,Input, ViewEncapsulation, ViewChild, ViewContainerRef, ComponentFactoryResolver, HostListener, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { environment } from 'src/environments/environment';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ExternalService } from '../services/external.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import * as global from 'src/app/globals';
declare var $: any;
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FooterComponent implements OnInit {
  data: Entry<any>[] = [];
  arrFields = [];
  footerData: any;
  componentRef: any;
  addbackToTop: Boolean = true;
  emailAlredyExist: any;
  nlPrivacyLink: any;
  languageData: any;
  routerPrivacyLink: any;
  newsletterIcon: any;
  showFooter: Boolean = false;
  footerisLoaded: Boolean = false;
  showErrorMessage: boolean = false;
  @ViewChild('backToTopButton') backToTopButton: ElementRef;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('loadProductComponent', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('footerContainer', { read: ViewContainerRef, static: true }) footercontentContainer: ViewContainerRef;
  userEmail: "";
  @Input() newsletterId: string = 'newsletter';
  updateAvailable: boolean;
  constructor(private contentfulService: ContentfulService, private route: Router, private resolver: ComponentFactoryResolver, private readonly svc: ExternalService, private dataService: DataService, private globalService: GlobalService, private cdref: ChangeDetectorRef) {

    this.newsletterIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/newsletter_icon.svg';
       this.contentfulService.$loadFooterAtLast.pipe(takeUntil(this.destroyed$)).subscribe(response => {
      if (response === true) {
        this.showFooter = true;
        //
        this.setupQuill();
      }
      else {
        this.showFooter = false;
        // this.footercontentContainer.clear();

      }

    });


    this.contentfulService.$updateAvailable.pipe(takeUntil(this.destroyed$)).subscribe(response => {
      if (response === true) {
        this.updateAvailable = true;

      }
      else {
        this.updateAvailable = false;

      }

    });

  }

  ngOnInit(): void {
    // this.svc.lazyCookiesQuill().subscribe(_ => {
    //  this.setupQuill();
    // });
    // this.svc.lazyCookiesQuill().subscribe(_ => {
    //  });
    let dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
    this.routerPrivacyLink = dicUrls['PRIVACYPOLICY_PREVIEW'];
    if (this.globalService.gettLanguageData() != undefined) {
      this.languageData = this.globalService.gettLanguageData();
      this.nlPrivacyLink = (this.languageData.web.footer_nl_section_text != undefined) ? this.languageData.web.footer_nl_section_text : '';
      let anchorTag = `<a class="nl-privacy-link">${this.languageData.web.footer_nl_section_text_privacy_link}</a>`;
      this.nlPrivacyLink = this.nlPrivacyLink.replace('%d', anchorTag);
    }
    else {
      this.globalService.languageDataEmiter.pipe(takeUntil(this.destroyed$)).subscribe(data => {
        if (data) {
          this.languageData = data;
          this.nlPrivacyLink = (this.languageData.web.footer_nl_section_text != undefined) ? this.languageData.web.footer_nl_section_text : '';
          let anchorTag = `<a class="nl-privacy-link">${this.languageData.web.footer_nl_section_text_privacy_link}</a>`;
          this.nlPrivacyLink = this.nlPrivacyLink.replace('%d', anchorTag);
        }
      });
    }

  }
  @HostListener('click', ['$event'])
  onClick(e) {
    if (e.target.classList.contains("nl-privacy-link")) {
      this.route.navigateByUrl(this.routerPrivacyLink);
    };
  }

  setupQuill() {
  //  
    if (this.showFooter && !this.footerisLoaded) {
      this.contentfulService.getContentByContentType('footerinfo')
        .then(data => {

          if (data.items) {
            this.footerData = data.items[0].fields;
            this.footerisLoaded = true;
            this.loadFooter(this.footerData);
          }
        })
    }

  }
  gotoNextPage(url) {
    if (url.indexOf("www") == 0 || url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
      if (url.indexOf("www") == 0) {
        url = "https://" + url;
      }
      window.open(url, '_blank');
      return false;
    }
    document.body.classList.remove("sticky-price-div");
    this.showFooter = false;
    let currentUrl= this.route.url.substring(1).split('/')[0];
    window.scrollTo(0, 0);

    if(url!=currentUrl){
      this.footercontentContainer.clear();
      this.contentfulService.emitFooterAtLast(false);
      this.cdref.markForCheck();
    }else{

      this.showFooter=true;
      this.footerisLoaded=false;
    }

    setTimeout(() => {
      this.route.navigate([url])
      .then(() => {

      });

    }, 200);

  }

  gotoNextPagePayment(url) {
    if (url.indexOf("www") == 0 || url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
      if (url.indexOf("www") == 0) {
        url = "https://" + url;
      }
      window.open(url, '_blank');
      return false;
    }
    document.body.classList.remove("sticky-price-div");
    this.showFooter = false;
    let currentUrl= this.route.url.substring(1).split('/')[0];
    window.scrollTo(0, 0);
    if(url!=currentUrl){
      this.footercontentContainer.clear();
      this.contentfulService.emitFooterAtLast(false);
      this.cdref.markForCheck();
      if (global.lang == 'de') {
         url = '/zahlung';
    }
    else {
        url = '/payment';
    }
    }else{

      this.showFooter=true;
      this.footerisLoaded=false;
    }

    setTimeout(() => {
      this.route.navigate([url])
      .then(() => {

      });

    }, 200);

  }

  gotoNextPageShipping(url) {
    if (url.indexOf("www") == 0 || url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
      if (url.indexOf("www") == 0) {
        url = "https://" + url;
      }
      window.open(url, '_blank');
      return false;
    }
    document.body.classList.remove("sticky-price-div");
    this.showFooter = false;
    let currentUrl= this.route.url.substring(1).split('/')[0];
    window.scrollTo(0, 0);
    if(url!=currentUrl){
      this.footercontentContainer.clear();
      this.contentfulService.emitFooterAtLast(false);
      this.cdref.markForCheck();
      if (global.lang == 'de') {
        url = '/versand';
   }
   else {
       url = '/shipping';
   }
    }else{

      this.showFooter=true;
      this.footerisLoaded=false;
    }

    setTimeout(() => {
      this.route.navigate([url])
      .then(() => {

      });

    }, 200);

  }

  findNodeElement(node) {
    for (var key in node) {
      if (typeof (node[key]) === 'object') {
        for (var innerKey in node[key].fields) {
          if (node[key].fields[innerKey].nodeType) {
            this.recursiveFnForRemovalOfEmptyPTag(node[key].fields[innerKey])
          }
          else {
            this.findNodeElement(node[key].fields[innerKey])
          }
        }

      }
    }
  }
  recursiveFnForRemovalOfEmptyPTag(node) {
    if (node.content) {
      var contentLength = node.content.length;
      var i = 1
      for (var key in node.content) {
        if (i == contentLength && node.content[key].nodeType == 'paragraph') {
          let blankParaLength = node.content[key].content.filter(x => x.value == '').length;
          if (node.content[key].content.length == blankParaLength) {
            node.content.splice(i - 1, 1);
          }
        }
        i++;
        if (node.content[key]) {
          this.recursiveFnForRemovalOfEmptyPTag(node.content[key]);

        }
      }
    }
  }
  loadFooter(data) {
    for (var key in data) {
      if (typeof (data[key]) == 'object') {
        if (key == 'colLogoAddress') {

          this.footerData.companyAddress = documentToHtmlString(data[key].fields.companyAddress);

          this.footerData.companyAddress = this.contentfulService.removeBlankTags(this.footerData.companyAddress);
          this.footerData.coLogo = data[key].fields.coLogo.fields.file.url;

        }
        else if (key == 'col1' || key == 'col2' || key == 'col3' || key == 'col4' || key == 'col5' || key == 'col6' || key == 'col7' || key == 'colAppStore') {
          this.footerData[key] = data[key];
        }
      }
    }
    //  setTimeout(() => {
    //     $(document).ready(function() { $('p:empty').remove(); });
    //     }, 500);
  }

  scrollToTop() {
    this.contentfulService.emitIsLoading(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }



  @HostListener("window:scroll", ['$event'])
  onWindowScroll(event) {
    if (this.backToTopButton) {
      let offsetTop = this.backToTopButton.nativeElement.offsetTop + 200;
      if (window.pageYOffset >= offsetTop) {
        this.addbackToTop = false;
        document.getElementById("backToTop").classList.add("show");
      } else {
        this.addbackToTop = true;
        document.getElementById("backToTop").classList.remove("show");
      }
    }
  }

  // emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // NewsletterForm = new FormGroup({
  //   email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern(this.emailPattern), this.clearInputEmail.bind(this)])
  // })

  newsLetter(userEmail: string, form:any) {
    if (form["valid"]) {
      var type = "subscribe"
      this.dataService.newsletterSubscribe(userEmail, type).subscribe(res => {
        this.emailAlredyExist = res;
      })
    }
    else{
      this.showErrorMessage = true
    }
    this.clearInputEmail.bind(this);
    form.reset();
  }

  onKey() {
      this.showErrorMessage = false;
  }

  clearInputEmail() {
    this.emailAlredyExist = null;
  }
}
